<template>
<div>
  <nut-navbar
    :left-show="false"
    @on-click-icon="titIconClick"
    :title="$t('bankCard.h1')"
    titIcon="home"
    fixed=true
    class="mb0 nav-icon-right nut-navbar--fixed">
    <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>



  <div class="login form-add">
    <div class="login-bn">&nbsp;</div>
    <div class="login-bd">
      <h3>{{edit ? editTitle : addTitle}}</h3>
      <p class="addcard-text">{{$t('bankCard.addcard_text')}}</p>
      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.realName')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="realNameErrText">{{realNameErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.realNamePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.realName"
          @inputChange="(res) => (bankInfo.realName = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.ifscCode')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="ifscCodeErrText">{{ifscCodeErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.ifscCodePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.ifscCode"
          @inputChange="(res) => (bankInfo.ifscCode = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.bankName')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="bankNameErrText">{{bankNameErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.bankNamePlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.bankName"
          @inputChange="(res) => (bankInfo.bankName = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.bankAccount')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="bankAccountErrText">{{bankAccountErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.bankAccountPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.bankAccount"
          @inputChange="(res) => (bankInfo.bankAccount = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.upiVpa')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="upiVpaErrText">{{upiVpaErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.upiVpaPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.upiVpa"
          @inputChange="(res) => (bankInfo.upiVpa = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{$t('bankCard.email')}}<span class="c-red">*</span></p>
        <p class="err-info" v-show="emailErrText">{{emailErrText}}</p>
        <login-input
          label=""
          :placeholder="$t('bankCard.emailPlaceholder')"
          type="text"
          rule="^.{4,30}$"
          maxLength="30"
          v-model="bankInfo.email"
          @inputChange="(res) => (bankInfo.email = res)"
        />
      </div>

      <div class="login-btn">
        <nut-button block type="info" @click="addBankCard"
          >{{$t('global.confirm')}}</nut-button
        >
      </div>
    </div>
  </div>
</div>

</template>

<script>
import { reactive } from "vue";
import { useRouter } from "vue-router";
import loginInput from "@/components/loginInput.vue";
import axios from "axios";
import { Toast } from "@nutui/nutui";
import { getCountry } from "@/assets/js/environment";

export default {
  components: {
    loginInput,
  },
  data() {
    return {
      country: '',
      editTitle: this.$t('bankCard.editTitle'),
      addTitle: this.$t('bankCard.addTitle'),
      edit:false,
      id: null,
      target: null,
      bankInfo: {
        realName: "",
        ifscCode: "",
        bankName: "",
        bankAccount: "",
        upiVpa:"",
        email: "",
      },
      realNameErrText: '',
      ifscCodeErrText: '',
      bankNameErrText: '',
      bankAccountErrText: '',
      upiVpaErrText: '',
      emailErrText: '',
      GCashErrText: '',
    };
  },

  methods: {
    gettingData() {
      if(this.$route.query.id){
        this.edit = true
        this.id = this.$route.query.id
        this.bankInfo.realName = this.$route.query.realName
        this.bankInfo.ifscCode = this.$route.query.ifscCode
        this.bankInfo.bankName = this.$route.query.bankName
        this.bankInfo.bankAccount = this.$route.query.bankAccount
        this.bankInfo.upiVpa = this.$route.query.upiVpa
        this.bankInfo.email = this.$route.query.email
      }
      if(this.$route.query.target){
        this.target  = this.$route.query.target
      }
    },
    addBankCard() {
      this.bankInfo.realName = this.bankInfo.realName.replace(/\s*/g,"")
      this.realNameErrText = ''
      this.ifscCodeErrText = ''
      this.bankNameErrText = ''
      this.bankAccountErrText = ''
      this.upiVpaErrText = ''
      this.emailErrText = ''
      if(this.bankInfo.realName === ""){
        this.realNameErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.ifscCode === ""){
        this.ifscCodeErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.bankName === ""){
        this.bankNameErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.bankAccount === ""){
        this.bankAccountErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.upiVpa === ""){
        this.upiVpaErrText = this.$t('global.errInfoRequired')
        return
      }else if(this.bankInfo.email === ""){
        this.emailErrText = this.$t('global.errInfoRequired')
        return
      }else{
        //const res = await axios
        axios
          .post("/api/user/bank/save", {
            id: this.id,
            bankInfo: this.bankInfo
          })
          .then((response) => {
            if (response.data.code === 0) {
              Toast.success(this.$t('bankCard.success'));
              setTimeout(() => {
                if(this.target){
                  this.$router.push({
                    path: this.target,
                    query: {
                      amountType: 'coin'
                    }
                  });
                }else{
                  this.$router.push("/bankCard");
                }
              }, 1000);
            } else if (response.data.code === 1004){
              let res = response.data.data
              Toast.fail(this.$t('global.INVALID_INPUT_VALUE'));
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "realName":
                    this.realNameErrText = res[i].description
                    break;
                  case "ifscCode":
                    this.ifscCodeErrText = res[i].description
                    break;
                  case "bankName":
                    this.bankNameErrText = res[i].description
                    break;
                  case "bankAccount":
                    this.bankAccountErrText = res[i].description
                    break;
                  case "upiVpa":
                    this.upiVpaErrText = res[i].description
                    break;
                  case "email":
                    this.emailErrText = res[i].description
                    break;
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            Toast.fail(this.$t('global.networkError'));
          });
      }
    }
  },
  watch: {
    '$route': 'gettingData'
  },
  created() {
    this.country = getCountry()
    this.gettingData()
  },
  setup() {
    const state = reactive({});
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      titIconClick(){
        router.push({
        name: "myHome",
      });
      }
    };
    return {
      ...methods,
      state,
    };
  },
};
</script>
<style scoped>


.addcard-text{ line-height: 16px; text-align: center; background: #fff5ed; padding: 10px; margin: 0 0 10px 0; color: #916d52; border-radius:5px;}
.c-red{color:rgb(243, 0, 0); margin-left: 5px;}
.login-inp{position: relative;}

.login {
  background: url(../assets/images/bg05.png) 0 -50px no-repeat;
  background-size: 100% auto;
}
.login-bd {
  padding: 20px;
  background: #fff;
  margin: 20px;
  border-radius: 10px;
}
.login-bd h3 {
  margin: 10px 0 10px 0;
  font-size: 20px;
  color: #333;
  font-weight: 400;
  text-align: center;
}
.login-bd .nut-input {
  padding: 5px 0 5px 0px;
}
.login-btn {
  margin: 30px 0 10px 0;
}
.login-bn {
  padding: 20px 20px 0px 20px;
}
.login-bn h2 {
  padding: 0;
  margin: 0;
  font-size: 40px;
  text-align: center;
  color: #efe7f4;
}
.otpbtn {
  float: right;
}
.nut-input-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.w100 .input-text {
  width: 100% !important;
}
</style>
<style>
.nut-input .input-text {
  padding: 0 0px 0 0px !important;
  width: 60% !important;
}
.pop-bankName {
  width: 98% !important;
  height: 520px;
  top: 50%;
  border-radius: 10px;
  background: #fff !important;  text-align: center;overflow-y: hidden !important;
}
.pop-bankName .bd{ margin: 10px;}
</style>