<template>
  <div>
  <nut-navbar
    :left-show="false"
    @on-click-icon="iconClick"
    class="mb0 nav-icon-right nut-navbar--fixed top-desc"
    :title="$t('recharge.h1')"
    titIcon="horizontal"
    fixed=true
  >
  <template #left>
      <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
    </template>
  </nut-navbar>

    <div class="c2c-box">

      <div class="c2c-hd">
        <div class="a"><strong>{{ $filters.currencySymbol(applyAmount) }}</strong></div>
        <div class="o">Transaction ID: <br/><span>{{ orderNum }}</span></div>
        <div class="i">
          UPI: <br/>
          <strong>
            {{ ((receiveInfo).split(':')[1].substring(1)).substring(0,((receiveInfo).split(':')[1]).length - 3) }}
          </strong>
          <span  @click="copyClick()">Copy</span>
        </div>
      </div>

      <div class="c2c-bd" :class="bgimg">

        <div class="c1">
          <h5>1 If you have paid successfully, please upload a screenshot of the payment and we will process it within a short time.</h5>

          <div class="file-box" @click="callFile">
            <!-- <img :src="uploadImgUrl" > -->
            <img :src="uploadImgBase64" v-if="uploadImgBase64" >
            <div class="v" v-if="uploadImgBase64" @click="checkImg($event)"></div>
          </div>

          <input type="file" id="file" accept="image/*" @change="getPicture($event)" style="display: none;" />

          <div style="float: right; width: 110px; margin-top: -30px;">
            <nut-button type="info" disabled="disabled" v-if="uploadComplete">
              <span>Completed</span>
            </nut-button>
            <nut-button type="info" @click="uploadImgClick" :disabled="uploadImgBtnDisabled" v-else>
              <span>Upload</span>
            </nut-button>
            
          </div>

        </div>

        <div class="c2">
          <h5>2 Order Processing</h5>
          <p v-if="bgimg === 'bg3'"><strong>{{time}}</strong> Please wait, the order will be processed successfully soon</p>
        </div>
        


        <div class="c3">
          <h5>3 Complete</h5>
        </div>
        
      </div>
      
    </div>

  </div>

  <!--**************************************** 图片预览 ****************************************-->
  <nut-popup :style="{ width: '90%' }"  v-model:visible="showPreview">
    <div class="preview-pop">
      <img :src="uploadImgBase64">
    </div>
  </nut-popup>

  <!--**************************************** 到帐 ****************************************-->
  <nut-popup pop-class="pop-invite" v-model:visible="showComplete" :z-index="100">
    <div class="hd">Successful</div>
    <div class="bd">
      Your recharge has arrived. Good luck!<br/>
      <strong>{{ $filters.currencySymbol(applyAmount) }}</strong>
    </div>
    <div class="btn">
      <nut-button type="primary" @click="toHome">{{$t('global.okText')}}</nut-button>
    </div>
  </nut-popup>


</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { getCountry } from "@/assets/js/environment";
import { Toast, Dialog} from "@nutui/nutui";


export default {

  data(){
    return{
      country:'',
      imgUrl:'',
      template:'',
      commonInfo:'',
      telegramChannelUrl:'',
      i18n:{
        loading: this.$t('global.loading'),
        networkError: this.$t('global.networkError'),
        failSelect: this.$t('recharge.failSelect'),
        failEnter: this.$t('recharge.failEnter'),
        success: this.$t('recharge.success'),
        successfully: this.$t('global.successfully'),
        rechargeBtnText: this.$t('global.rechargeBtnText'),
        copied: this.$t('global.Your_invitation_link_has_been_copied'),
      },
      applyAmount: '',
      orderNum: '',
      receiveInfo: '',
      status: '',
      uploadImgUrl: '',
      uploadImgBase64: '',
      bgimg: 'bg1',
      uploadImgBtnDisabled: true,
      uploadComplete: false,
      showPreview: false,  //图片预览
      time: 60,
      uploadImgForm:{
        orderNum: '',
        screenshot: null,
      },
      showComplete: false,
      showComplaining: false,
      showFailed: false,
      setIntervalFn: null
    }
  },
  created() {

    this.applyAmount = this.$route.query.a;
    this.orderNum = this.$route.query.o;
    this.receiveInfo = this.$route.query.i;
    this.uploadImgForm.orderNum = this.$route.query.o;

    this.country = getCountry()
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.template = document.querySelector('body').getAttribute('s')
    if(window.localStorage.getItem('commonInfo')){
      this.commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
      this.telegramChannelUrl = this.commonInfo.telegramChannelUrl
    }else{
      axios.get("/api/system/common", {}).then((response)=> {
        this.telegramChannelUrl = response.data.data.telegramChannelUrl
      })
    }
  },
  methods:{
    getPicture(e){
      this.uploadImgUrl = window.URL.createObjectURL(e.target.files[0]);
      var reader = new FileReader();
      reader.onload = (e) => {
        this.uploadImgBase64 = e.target.result
      }
      this.uploadImgBtnDisabled = false
      this.bgimg = 'bg2'
      this.uploadImgForm.screenshot = e.target.files[0]
      reader.readAsDataURL(e.target.files[0])
    },
    callFile(){
      if(this.uploadComplete){
        return
      }else{
        let fileDom = document.querySelector("#file")
        fileDom.click()
      }
    },
    checkImg(e){
      this.showPreview = true;
      e.stopPropagation();
      e.preventDefault()
    },
    //上传图片
    uploadImgClick(){
      let formData = new FormData();
      for( var key in this.uploadImgForm){
        formData.append(key,this.uploadImgForm[key])
      }
      this.uploadImgBtnDisabled = true
      axios({
        method: "post",
        url: "/api/fund/recharge/c2c/confirm",
        headers:{
          "Content-Type": "multipart/form-data"
        },
        withCredentials:true,
        data: formData
      }).then((res) => {
        if (res.data.code === 0) {
          this.uploadComplete = true
          this.bgimg = 'bg3'

          this.setIntervalFn = setInterval(() => {
              if(this.time > 0){
                if((this.time % 3) === 0){
                  this.getRechargeStatus()
                }
                this.time -= 1
              }else{
                this.time = 0
                this.bgimg = 'bg4'
                this.getRechargeStatus(0)
                clearInterval(this.setIntervalFn)
                
              }
            }
          ,1000)

          // let setIntervalFn2 = setInterval(() => {
          //   axios({
          //     url: "/api/fund/recharge/query-status",
          //     method: "get",
          //     params: { orderNum: this.orderNum }
          //   }).then((res) => {
          //     this.status = res.data.data.value
          //     switch (res.data.data.value) {
          //       case "success": //充值成功
          //         this.showComplete = true
          //         clearInterval(setIntervalFn)
          //         clearInterval(setIntervalFn2)
          //         break;
          //       case "c2c_complaining":  //申诉中
          //         this.showComplaining = true
          //         clearInterval(setIntervalFn)
          //         clearInterval(setIntervalFn2)
          //         Dialog({
          //           title: 'Order exception',
          //           content: 'There is an exception in this transaction',
          //           okText: this.$t('game.gotitBtn'),  
          //           noCancelBtn: true
          //         });
          //         break;
          //       case "failed":  //充值失败
          //         this.showFailed = true
          //         clearInterval(setIntervalFn)
          //         clearInterval(setIntervalFn2)
          //         Dialog({
          //           title: 'Order exception',
          //           content: 'There is an exception in this transaction',
          //           okText: this.$t('game.gotitBtn'),  
          //           noCancelBtn: true
          //         });
          //         break;
          //     }
          //   })
          // }
          // ,3000)
        }
      })
    },

    //获取状态
    getRechargeStatus(t){
      axios({
        url: "/api/fund/recharge/query-status",
        method: "get",
        params: { orderNum: this.orderNum }
      }).then((res) => {
        this.status = res.data.data.value
        switch (res.data.data.value) {
          case "success": //充值成功
            this.showComplete = true
            this.bgimg = 'bg4'
            clearInterval(this.setIntervalFn)
            break;
          case "c2c_complaining":  //申诉中
            this.showComplaining = true
            clearInterval(this.setIntervalFn)
            Dialog({
              title: 'Order exception',
              content: 'There is an exception in this transaction',
              okText: this.$t('game.gotitBtn'),  
              noCancelBtn: true
            });
            break;
          case "failed":  //充值失败
            this.showFailed = true
            clearInterval(this.setIntervalFn)
            Dialog({
              title: 'Order exception',
              content: 'There is an exception in this transaction',
              okText: this.$t('game.gotitBtn'),  
              noCancelBtn: true
            });
            break;
          case "c2c_user_confirm":  
            if(t === 0){ //倒计时结束 未成功
              Dialog({
                title: 'Order timeout',
                content: 'Transaction will be completed within 5 minutes',
                okText: this.$t('game.gotitBtn'),  
                noCancelBtn: true
              });
            }
            break; 
        }
      })
    }


  },
  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      rechargeing:false,
    });
    const router = useRouter();
    const methods = {
      toRechargeUsdt(){
        router.push({
          name: "rechargeUSDT",
        });
      },
      iconClick() {
        router.push({
          name: "rechargeHistoryList",
        });
      },
      backClick() {
        router.go(-1);
      },
      toHome() {
        router.push({
          name: "myHome",
        });
      },
      copyClick(){
        let cInput = document.createElement("input")
        cInput.value = ((this.receiveInfo).split(':')[1].substring(1)).substring(0,((this.receiveInfo).split(':')[1]).length - 3)
        document.body.appendChild(cInput)
        cInput.select()
        document.execCommand("copy")
        Toast.success(_data.data.i18n.copied)
        document.body.removeChild(cInput)
      },

    };

    onMounted(() => {
    });
    return {
      ...toRefs(state),
      ...methods,
    };
  },
};
</script>
<style scoped>

.pop-ab {
  padding: 0px !important;
  width: 100% !important;
  min-height: 100px;
  top: 50%;
  border-radius: 0px;
  background: transparent !important;  text-align: center;overflow-y: hidden !important;
}
.pop-ab .nut-button--round{height: 30px !important; margin-top:5px;}
.pop-ab .bd{ margin: 0; padding: 3px 0px !important; background: #000;}
.pop-ab img{ width: 100% !important; height: auto; margin: 0; max-width: 400px !important;}
.pop-ab .nut-swiper-inner{ height: auto !important; }

.f-24{color: #fd0;font-size: 24px; font-weight: 500; margin-left: 3px;}
.p-info{ line-height: 16px; position:absolute; right: 20px; top:0px; color:#940d01;}
.p-info .r{ float: right;}
.recharge-bd {
  background: url(../assets/images/bg05.png) 0 0px no-repeat;
  background-size: 100% 300px;
  min-height: 500px;
  margin-top: -5px;
  padding-top: 45px;
}
.recharge-btn {
  width: calc(33% - 4px);
  margin: 3px 2px;
  background: #ffffe2 url(../assets/images/bg149.png) center center no-repeat;
  background-size:450px auto;
  align-items: flex-start;
  text-align: center;
  border-radius: 5px;
  position: relative;
  border: 0;
  height: 60px;
}
.recharge-btn strong {
  line-height: 52px;
  font-size: 18px;
  font-weight: 400;
  color: #a83300;
}
.recharge-btn.active {
  background:#d4700e url(../assets/images/bg149b.png) center center no-repeat;
  background-size:450px auto;
}
.recharge-btn.active strong {
  color: #feff88;
  font-weight: 600;
}

</style>

<style>

</style>
