<template>
  <div class="first-recharge" style="">
    <div class="FR-top">
      <h2 class="f-40">{{$t('global.RECEBER_CUPOM_DE_DESCONTO_DE')}}{{ $filters.currencySymbol(commonInfo.firstRechargeGift) }}</h2>
      <h4>{{$t('global.VALID')}} {{commonInfo.firstRechargeDuration / 60}}H</h4>
    </div>
    <div class="FR-body">
      <div class="q-ls">
        <div class="q2" >
          <div class="l"><i>{{ $filters.currencySymbol(' ') }}</i>{{ commonInfo.firstRechargeGift }}</div>
        </div>
        <div class="t" @click="getCoupon">
          {{$t('global.OBTER_CUPOM')}}
        </div>
      </div>

      <h4>{{$t('global.hotGame')}}</h4>
      <div class="link-2x2">
        <div  v-for="(item, index) in games" :key="index"  class="link-i" @click="toGameHome(item.name)">
          <img v-if="item.name === 'crash'" :src="require('../assets/images/' + imgUrl + 'gm1.png')" />
          <img v-if="item.name === 'parity'" :src="require('../assets/images/' + imgUrl + 'gm3.png')" />
          <img v-if="item.name === 'mine'" :src="require('../assets/images/' + imgUrl + 'gm2.png')" />
          <img v-if="item.name === 'wheel'" :src="require('../assets/images/' + imgUrl + 'gm4.png')" />
        </div>
      </div>

    </div>

  </div>

</template>

<script>
import { getCountry, } from "@/assets/js/environment";
import { useRouter } from "vue-router";
export default {
  name: "firstRechargeBonus",

  data() {
    return {
      country: '',
      games: '',
      commonInfo: '',
      imgUrl: '',
    };
  },
  created() {
    this.country = getCountry()
    this.commonInfo = JSON.parse(localStorage.getItem("commonInfo"))
    this.imgUrl = localStorage.getItem('countryImgUrl')
    this.games = this.commonInfo.games
  },
  mounted() {
  },
  methods: {
    getCoupon(){
      if(localStorage.getItem('token')){
        this.$router.push("/firstRechargeBonus");
      }else{
        this.$router.push("/userRegister");
      }
    }
  },
  setup() {
    const router = useRouter();
    const methods = {
      toGameHome(n){
        router.push('/' + n);
      },
    };
    return {
      ...methods,
    };
  },
};
</script>

<style scoped>
.first-recharge{ max-width: 600px;min-width: 360px; min-height: 600px; margin: 0 auto; position: relative; 
  background:#ffc454 url(../assets/images/bg304.jpg) center 0px no-repeat; background-size:100% auto;}
.first-recharge .FR-top{ text-align: center; max-width: 340px; margin: 0px auto; padding-top: 10px; color: #fff; height: 180px;}
.first-recharge .FR-top h2{ font-size: 55px; line-height: 52px; font-weight: 600; color: #fff; margin: 20px 0 0 0;letter-spacing:1px;
  text-shadow: 0 3px #ec7800, 3px 0  #ec7800, -3px 0  #ec7800, 0 -3px #ec7800;}
.first-recharge .FR-top h2.f-40{font-size: 40px; line-height: 42px;}
.first-recharge .FR-top h4{font-size: 18px;line-height: 40px; margin: 0; height: 40px; color: #a13d01;
  background: url(../assets/images/bg309.png) center center no-repeat; background-size:auto 40px; opacity: .8; }
.first-recharge .FR-body{ padding: 30px 10px 30px 10px;}
.first-recharge .FR-body .q-ls{ height: 185px; padding-top: 190px;
    background: url(../assets/images/bg305.png) center top no-repeat; background-size:auto 100%;}
.first-recharge .FR-body .q-ls .q2{ width: 280px; margin: 0 auto 30px auto; text-align: center; line-height: 82px; font-size: 80px; color: #ffd956; font-weight: 600;
  background: #fff url(../assets/images/bg310.png) 90px center no-repeat; background-size:auto 105px;}
.first-recharge .FR-body .q-ls .q2 .l{background: url(../assets/images/in/EN/S2/bg199c.png) right top no-repeat !important; background-size: 100% auto !important;
  padding-left: 40px; color: #a95800; text-align: left;height: 104px;}
.first-recharge .FR-body .q-ls .q2 i{ font-size: 40px; vertical-align: super; margin-right: 10px;}
.first-recharge .FR-body .q-ls .t{ text-align: center; height: 45px; width: 240px; margin: 0 auto; line-height: 42px;color: #fff; font-weight: 600;
  font-size: 23px;  background: url(../assets/images/bg306.png) center center no-repeat;  background-size:100% auto;}
.first-recharge .link-2x2{ display: flex; align-items: center; flex-wrap: wrap; margin: 0px;}
.first-recharge .link-2x2 .link-i{ text-align: center;width: 46%; margin: 5px 2%; border-radius: 10px;
    background:#da9a21 url(../assets/images/br/PT/S2/bg199c.png) right 0px no-repeat; background-size: 200% auto;}
  
.first-recharge .link-2x2 .link-i img{ width: 70%;}
.first-recharge h4{ text-align: center; margin: 30px 0 0 0; font-size: 20px; color: #965600;}
</style>
<style>

</style>
