<template>
  <div class="pt40 crash-home" :class="smoothMode ? 'smooth-main' : ''">
    <nut-navbar
        @on-click-icon="askClick"
        fixed=true
        :left-show="false"
        :desc=player.availableAmount
        class="mb0 nav-icon-right nut-navbar--fixed"
        :title="$t('game.h1')"
    >
    </nut-navbar>
    
    <nut-overlay v-model:visible="overlayShow" :close-on-click-overlay="false" :z-index="2000" :overlay-class="overlayClass">
      <div class="tips-list">
        {{ $t('game.tips') }}: {{ tipsList[Math.trunc(6 * Math.random())] }}
      </div>
    </nut-overlay>

    <div class="games-main">

      <div class="g-vm">
        <!-- 历史 -->
        <div class="top">
          <span v-for="(history,index) in game.gameHistoryList" :class="getGameHistoryClass(history,index)" :key="index">
            {{ history.r }}x
          </span>
        </div>
        
        <div v-show="commonInfo.pureMode" class="geme-demo-btn" @click="toGameDemo"></div>

        <div class="canvas-main">
          <!-- 画板 -->
          <canvas id="canvas" width="400"  height="250" style=" width: 400px; height:250px !important; overflow: hidden;"></canvas>
          <img :src="draw.planeImg" id="canvas-img1" ref='plane' width="0"/>
          <img :src="require('../assets/images/' + imgUrl + 'fjbg.jpg')" id="canvas-img2" ref='background' width="0" style="top:-3680px"/>
          <img :src="require('../assets/images/' + imgUrl + 'fjbg2.png')" id="canvas-img5" ref='background2' width="0"/>
          <!-- 倍率 -->
          <div class="rocket-data" v-show="game.on">
            <strong>{{ game.currentRatio }}</strong>
            <span style="font-size:30px; background: none;padding-left: 5px;">X</span>
          </div>
        </div>
        <!-- 本局id -->

        <!-- <div class="top-left" @click="smoothModeToggle">
          <span class="f12">Smooth mode : </span><strong class="g" v-if="smoothMode">ON</strong><strong class="r" v-else>OFF</strong>
        </div> -->

        <div class="bottom-center" v-show="game.on === true || game.stage === stageEnum.end">
          <span  v-if="this.player.myWinAmount !== null && this.player.myWinAmount !== 0" class="c-y">
            {{$t('global.myWin')}}: {{ $filters.currencySymbol(player.myWinAmount) }}  
            <span style="margin-left: 5px; padding-left: 5px;">{{ player.escapeRatio }}X</span>
          </span>
        </div>

        <!-- <div class="bottom-left">
          <span v-show="game.escapedPlayerCount > 0">{{ $t('game.stopPlayer') }}: {{ game.escapedPlayerCount }}</span>
        </div> -->
        
        

        

        <!-- 暂停状态 -->
        <div class="state1-preparing typebg0" v-show="game.pause">
          <h3>{{ $t('game.gamePaused') }}</h3>
          <p></p>
        </div>

        <!-- 开始倒计时状态 -->
        <div class="state1-preparing typebg1" v-show="game.stage===stageEnum.bet">
          <h3>{{ $t('game.playTime') }}</h3>
          <p style="opacity: .8;">{{ $t('game.gameWillStartingIn') }}
            <nut-countdown :endTime="game.betDeadline" millisecond format="ss:SS" style="font-size:30px;"/>
          </p>
          <div class="djs">
            <div class="djs-c" :style="{'width': game.progressBarWidth}">
            </div>
          </div>
        </div>

        <!--        <div class="state1-preparing" :class="player.playStatus === playerStatusEnum.escape ? 'typebg2-my' : 'typebg2' "  v-show="game.stage===stageEnum.end && game.statistics">-->
        <!--          <div class="top-user">-->
        <!--            <div>-->
        <!--              <p style="padding-top:100px"><strong>Statistics...</strong></p>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </div>-->
        
        <!-- 结算状态 -->
        <div class="state1-preparing typebg2" v-show="game.stage===stageEnum.end">
          <div class="top-user-new">
            <h5>Bang @ <strong>{{ game.endRatio }}</strong>X</h5>
            <!-- <p v-if="player.playStatus === playerStatusEnum.escape" style="margin-top:8px">{{ $t('game.youWin') }} :
              <strong>{{ $filters.currencySymbol(player.escapeAmount) }}</strong>
            </p>
            <p v-else style="margin-top:8px" >{{ game.escapedPlayerCount }} Players {{ $t('game.receiveBonus') }}:
              <strong>{{ $filters.currencySymbol(game.lastGameWinAmount) }}</strong>
            </p> -->

          </div>


        </div>

        <!-- 遮罩 -->
        <div class="state1-preparing typebg9"></div>

        <div class="bottom-left">
          <span @click="rulePopup = !rulePopup">Rule</span>
        </div>

      </div>
      <!-- WIN玩家飘屏 -->
      <nut-noticebar
          direction="vertical"
          :list="game.lastGameBigWinners"
          :speed="20"
          :standTime="1000"
          :height="30"
          :closeMode="true"
          :background="`rgba(251, 248, 220, 1)`"
          :color="`#D9500B`"
          right-icon="circle-close"
      ></nut-noticebar>


      <!-- 下注面板 -->
      <div class="g-operate">
        <div class="top-betbtn">
          <nut-button v-if="game.stage===stageEnum.bet && player.playStatus===playerStatusEnum.notBet"
            @click="bet" block type="info" class="btn-bg-g" :disabled="betDisabled">
            <span v-if="!betDisabled">{{ $t('game.start') }}</span>
            <nut-icon v-else name="loading" size="30" ></nut-icon>

            
            
          </nut-button>

          <nut-button v-else-if="game.stage===stageEnum.flying && player.playStatus===playerStatusEnum.bet"
            @click="escape(false)" block type="danger" class="btn-bg-o" :disabled="escapeDisabled">
            <span v-if="!escapeDisabled">{{ $t('game.stop') }}</span>
            <nut-icon v-else name="loading" size="30" ></nut-icon>

            <span v-if="!escapeDisabled && this.player.betAmount2 !== null" style="margin-left: 10px;">{{$filters.currencySymbol(player.currentWinAmount)}}</span>

          </nut-button>
          
          <nut-button v-else block type="danger" class="btn-bg-d" @click="nextRound">
            {{ $t('game.start') }}
            <span v-if="player.betAmount2 > 0">My Bet:<strong style="font-size: 16px; margin-left: 6px;"> {{ $filters.currencySymbol(player.betAmount) }}</strong></span>
            <span v-else>{{$t('game.nextGame')}}</span>
          </nut-button>
        </div>
        <div class="g-amount">
          <div class="inpbox">
            <input type="text" v-model="player.betAmount" @change="betAmountMax"/>
            <!--
            <div class="r">
              <nut-button v-if="player.betAmount !== null" shape="square" type="primary" icon="close" size="mini"
                          @click="player.betAmount = null"></nut-button>
            </div>
            -->
            <button class="btn-bet btn-sub" @click="btnSubtract">-</button>
            <button class="btn-bet btn-plus" @click="btnAddition">+</button>
          </div>
          <div class="btnbox">
            <nut-button v-for="recommendBetAmount in game.recommendBetAmounts" :key="recommendBetAmount"
                        size="mini" type="primary" @click="player.betAmount=recommendBetAmount">
              {{ recommendBetAmount }}
            </nut-button>
            <nut-button size="mini" type="primary" @click="betAmountMax(player.availableAmount)"> {{ $t('game.all') }}
            </nut-button>
          </div>
        </div>

        <!-- <div class="g-amount-r">
          <h4>
            <span v-if="player.availableAmount !== null" class="tx"><span>{{ player.availableAmount }}</span></span>
          </h4>
        </div> -->

        <h4 class="mt20">
          <span class="mr20">{{ $t('game.autoStop') }}</span>
          <nut-inputnumber
              v-model="player.autoEscapeRatio"
              step="0.10"
              decimal-places="1"
              min="1.1"
              max="100.00"
              @change="updateAutoEscape"
              :disabled="!player.autoEscape"

          />
          <span class="r" style="margin-left: 10px;"><nut-switch @change="updateAutoEscape" v-model="player.autoEscape"/></span>

          <span class="r r2" v-if="player.betAmount2 > 0">{{ $t('game.myBet') }} : <strong> {{
              $filters.currencySymbol(player.betAmount2)
            }}</strong></span>
        </h4>

        <!-- <div class="g-amount-r">
          <h4>
            <span v-if="player.availableAmount !== null" class="tx"><span>{{ player.availableAmount }}</span></span>
          </h4>
        </div> -->
        
      </div>

      <!-- 玩家列表 -->
      <div class="g-players" v-show="!smoothMode">
        <h4>
          <strong :class="tabActive ? 'active' : ''" @click="playersList">
            <nut-icon name="my"></nut-icon>
            {{ $t('game.players') }}:
            <span>{{ this.game.totalPlayerCount }}</span>
          </strong>
          <strong :class="tabActive ? '' : 'active'" @click="myHistoryList">
            <nut-icon name="date"></nut-icon>
            {{ $t('game.myHistory') }}
          </strong>
        </h4>

        <ul v-show="tabActive">
          <li v-if="this.game.totalPlayerCount > 0">
            <span class="l" style="width:90px;color: #6f5675;">{{ $t('game.players') }}</span>
            <span class="l" style="width:80px;color: #6f5675;">{{ $t('game.betAmount') }}</span>
            <span class="l" style="width:60px;color: #6f5675;">{{ $t('game.winRatio') }}</span>
            <span class="r" style="color: #6f5675;">{{ $t('game.winAmount') }}</span>
          </li>

          <li v-for="(playerls) in game.players" :key="playerls.id" :class="playerls.id === player.id ? 'myBet' : ''">

            <span class="l" style="width:90px"><img :src="playerls.avatar"/>{{ playerls.mobile }}</span>

            <span class="l" style="width:80px">{{ $filters.currencySymbol(playerls.betAmount) }}</span>

            <span v-show="playerls.escapeAmount!==null" class="l" style="width:60px;">{{ playerls.escapeRatio }}x</span>

            <span v-show="playerls.escapeRatio!==null" class="r"
                  style="color: #bbaf78;">{{ $filters.currencySymbol(playerls.escapeAmount) }}</span>

          </li>
          <li v-if="this.game.playersMore" style="text-align: center;opacity:0.5;"><span>{{ $t('game.last20') }}</span>
          </li>
        </ul>

        <ul v-show="!tabActive" class="mylist">
          <li>
            <span class="l" style="width:90px;color: #6f5675;">{{ $t('game.feeAmount') }}</span>
            <span class="l" style="width:60px;color: #6f5675;">{{ $t('game.betAmount') }}</span>
            <span class="l" style="width:60px;color: #6f5675;">{{ $t('game.ratio') }}</span>
            <span class="r" style="color: #bbaf78;">{{ $t('game.winAmount') }}</span>
          </li>

          <li v-for="(item, index) in myHistoryData" :key="index">

            <span class="l" style="width:90px;">{{ item.betTime }}</span>

            <span class="l" style="width:60px">{{ $filters.currencySymbol(item.betAmount) }}</span>

            <span v-if="item.status === 'escape'" class="l" style="width:60px;">{{ item.escapeRatio }}x</span>
            <span v-else class="l" style="width:60px;">--</span>
            <span v-if="item.status === 'escape'" class="r"
                  style="color:#bbaf78;">+{{ $filters.currencySymbol(item.originWinAmount) }}</span>
            <span v-else class="r" style="color:#bbaf78;">--</span>
          </li>
          <li></li>
          <li style="text-align: center;" @click="iconClick">{{ $t('game.more') }} >></li>

        </ul>
      </div>

      <nut-popup position="left" closeable  :style="{ height: '100%',width:'70%' }" v-model:visible="rulePopup">
        <GameRules></GameRules>
      </nut-popup>


      <nut-popup position="bottom" closeable round :style="{ height: '70%' }" v-model:visible="historyPopup">
        <h4 class="h4" style="margin-left: 10px;">
          <nut-icon name="date" style="vertical-align: middle;margin-right: 5px;margin-bottom: 4px;"></nut-icon>
          {{ $t('game.historData') }}
        </h4>
        <GameHistory :ref="gameHistory" v-if="historyPopup"></GameHistory>
      </nut-popup>

      <nut-popup pop-class="pop-withdrawal" v-model:visible="withdrawalPopup" :z-index="100">
        <div class="hd">{{ $t('game.pleaseWithdraw1') }}</div>
        <div class="bd">
          {{ $t('game.pleaseWithdraw2') }} 
          <strong style="color:#208f0a">{{ commonInfo.forceWithdrawThreshold }}+ </strong>&#127881;&#127881;&#127881;
          {{ $t('game.pleaseWithdraw3') }}<br/>
          <strong>{{ $t('game.pleaseWithdraw4') }}</strong>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="withdrawClick">{{ $t('game.go') }}</nut-button>
          <span v-show="gotitBtn" class="link" @click="withdrawalPopup = false">{{ $t('game.gotitBtn') }}</span>
        </div>
      </nut-popup>

      <nut-popup pop-class="pop-invite" v-model:visible="invitePopup" :z-index="100">
        <div class="hd">{{ $t('game.info1') }}</div>
        <div class="bd">
          {{ $t('game.info2') }}<br/>
          <strong>{{ $filters.currencySymbol(game.fillUpAmount) }}</strong>
          <div class="app-task" v-show="appTaskStatus === 'forbid' || appTaskStatus === 'can_receive'" @click="downloadApp">
            {{ $t('global.DOWNLOAD_APP_TEXT2') }}
            <span>{{ $filters.currencySymbol(appTaskReward) }}</span>
            {{ $t('global.DOWNLOAD_APP_TEXT3') }}
          </div>
        </div>
        <div class="btn">
          <nut-button type="primary" @click="invitePopup = false">I got it</nut-button>
        </div>
      </nut-popup>

      <!--**************************************** Big win ****************************************-->
      <nut-popup pop-class="pop-top-bigwin" v-model:visible="bigwinData.show" position="left" :closeable="true" :overlay="false" style="top: 7px !important">
        <div class="bd">
          <!--div class="t1"><img class="avatar" :src="bigwinData.data.avatar" -->
          <div class="t1"><img class="avatar" :src="require('../assets/images/' + imgUrl + 'bigwin.png')" >
            <span>{{ bigwinData.data.mobile }}</span>
          </div>
          <div v-if="bigwinData.data.appTypeCode === 'crash'" class="t2 crash"><span>{{ bigwinData.data.crashEscapeRatio }}x</span></div>
          <div v-else-if="bigwinData.data.appTypeCode === 'parity'" class="t2 parity"><span>{{ bigwinData.data.parityRoomName }}</span></div>
          <div v-else-if="bigwinData.data.appTypeCode === 'wheel'" class="t2 wheel"><span> </span></div>
          <div v-else-if="bigwinData.data.appTypeCode === 'mine'" class="t2 mine"><span>{{ bigwinData.data.mineRowCount }} X {{ bigwinData.data.mineRowCount }}</span></div>
          <div class="t3"><span>WIN</span><strong>{{ bigwinData.data.winAmount }}</strong></div>
        </div>
      </nut-popup>

      <!--**************************************** Broadcast Msg ****************************************-->
      <nut-popup pop-class="pop-top-broadcast-msg" v-model:visible="broadcastMsgData.show" position="left" :closeable="true" :overlay="false" style="top: 5px !important;">
        <div class="bd">
          <div class="t4" v-if="broadcastMsgData.type === 'parity_prediction'">&nbsp;</div>
          <div class="t5"><span>{{ broadcastMsgData.message}}</span></div>
        </div>
      </nut-popup>

      <!--**************************************** 试玩或充值 ****************************************-->
      <nut-popup pop-class="pop-demo" v-model:visible="toRechargeDialog">
        <div class="hd">{{ $t('global.Recharge_Dialog_Title') }}</div>
        <div class="bd">
          {{ $t('global.Recharge_Dialog_Text') }}<br/>
        </div>
        <div class="btn" style="margin: 0;">
          <nut-button style="width: 47%;margin: 0 3% 0 0;" type="primary" @click="rechargeBtnClick">{{ $t('home.recharge') }}</nut-button>
        </div>
      </nut-popup>
      
    </div>
    <tabbar :activeIndex="2"></tabbar>
    <!--
      <component :is="tabbar"></component>
      -->
  </div>
</template>

<script>
// import {reactive, toRefs, ref, computed} from "vue";
import {fabric} from "fabric"
import TabbarMain from "../components/TabbarMain.vue";
import GameHistory from "../components/GameHistory.vue";
import GameRules from "../components/GameRules.vue";
import {Dialog, Toast} from '@nutui/nutui';
import {getValue, isBlank, isNotBlank, isTrue} from "@/assets/js/commonUtil";
import router from "@/router";
import axios from "axios";
import {getDeviceId} from "@/assets/js/visitor";
import { setLastTimeGameName } from "@/assets/js/environment";
import {commonEvent, createSocket, disconnectSocket, gameType} from "@/assets/js/game";
import { getCommonInfoAttr } from "@/assets/js/commonInfo";

export default {

  components: {
    tabbar: TabbarMain,
    GameHistory: GameHistory,
    GameRules: GameRules
  },
  data() {
    return {
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      imgUrl : localStorage.getItem('countryImgUrl'),
      overlayClass: 'overlay-div overlay-bg' + Math.trunc(2 * Math.random()),
      showViponly: false,
      gotitBtn: false,
      smoothMode: false,
      betDisabled: false,
      escapeDisabled: false,
      showDialogFill: true,
      showDialogRecharge: true,
      cancelText: 'OK',
      okText: 'OK',
      rechargeBtnText: this.$t('global.rechargeBtnText'),
      withdrawalBtnText: this.$t('global.withdrawalBtnText'),
      inviteBtnText: this.$t('global.inviteBtnText'),
      overlayShow: false,
      rulePopup: false,
      historyPopup: false,
      withdrawalPopup: false,
      invitePopup: false,
      toRechargeDialog: false,
      alreadyAlertMaxLimit: false,
      tabActive: true,
      listNoData: true,
      myHistoryData: '',
      tipsList: [
        this.$t('tips.tips1'),
        this.$t('tips.tips2'),
        this.$t('tips.tips3'),
        this.$t('tips.tips4'),
        this.$t('tips.tips5')
      ],
      draw: {
        canvas: null,
        plane: null,
        planeImg: require('../assets/images/'+ localStorage.getItem('countryImgUrl') +'fj.png'),
        planeOpacity: 1,
        planeBeginTop: 150,
        planeCenterTop: 80,
        planeUserTop: 0,
        planeBeginLeft: 186,
        planeCenterLeft: 186,
        planeUserLeft: 0,
        planeBeginAngle: 0,
        planeCenterAngle: 0, // -15
        planeUnit: 4,
        planeCenterRatio: null,
        planeBeginScale: 0.25, //0.25
        flame: 0,
        background: null,
        backgroundBeginTop: -3780,
        background2: null,
        background2BeginTop: 50,
        background2CenterTop: 300,
        background2BeginLeft: 0,
        background2CenterLeft: 0,
        flightPath: null
      },
      socket: null,
      game: {
        on: false,
        pause: false,
        serialNum: null,
        betDeadline: null,
        endDeadline: null,
        stage: null,
        expect: null,
        currentRatio: 1.00,
        startTimestamp: null,
        gameHistoryList: [],
        betAmountRange: null,
        recommendBetAmounts: [],
        fillUpAlertThreshold: null, //补满金币阈值
        fillUpAmount: null, //补满金币值
        totalPlayerCount: 0,
        players: [],
        escapedPlayerCount: 0,
        playersMore: false,
        lastGameBigWinners: [],
        delay: -1,
        lastGameTopList: '',
        lastGameWinAmount: '',
        latency: null,
        statistics: true,
        progressBar:0,
        progressBarWidth:'',
        progressBarSetIntervalFn: null,
        endRatio: 0,
      },
      player: {
        id: null,
        vipLevel: 0,
        vipMaxRatio: 100,
        avatar: null,
        availableAmount: null,
        rechargeAmount: null,
        withdrawAmount: null,
        playStatus: null,
        betAmount: 20,
        betAmount2: null,
        currentWinAmount: null,
        myWinAmount: null,
        escapeRatio: null,
        escapeAmount: null,
        autoEscape: false,
        autoEscapeRatio: 3.00,
        autoEscaping: false,
      },
      //event
      eventEnum: {
        initRoom: "s_cr_init_room", //初始化房间
        startBet: "s_cr_start_bet", //开始下注阶段
        startFlying: "s_cr_start_flying", //开始飞行阶段
        startEnd: "s_cr_start_end", //开始结束阶段
        playerInfo: "s_cr_player", //当前用户的玩家信息
        pause: "s_cr_pause", //游戏暂停
        betNotify: "s_cr_bet", //任意玩家的下注通知
        escapeNotify: "s_cr_escape",//任意玩家的逃跑通知
        bet: "c_cr_bet",//下注
        escape: "c_cr_escape",//逃跑
      },
      //游戏阶段
      stageEnum: {
        bet: "bet",
        flying: "flying",
        end: "end",
        pause: "pause"
      },
      //玩家状态
      playerStatusEnum: {
        notBet: "not_bet",//未下注
        bet: "bet",//已下注
        escape: "escape",//已逃跑
        crash: "crash",//已爆炸
        cancel: "cancel",//游戏取消
      },
      bigwinData: {
        show: false,
        start: false,
        list: [],
        data: {}
      },
      broadcastMsgData: {
        show: false,
        type: '',
        message: '',
      },
      appTaskStatus: '',
      appTaskReward: '',
    }
  },
  methods: {
    //下注加减
    btnAddition() {
      if (isBlank(this.player.betAmount)) {
        this.player.betAmount = 20
        return
      }
      if (this.player.betAmount <= 99) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 10)
      } else if (this.player.betAmount <= 999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 100)
      } else if (this.player.betAmount <= 9999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 1000)
      } else if (this.player.betAmount <= 99999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 10000)
      } else if (this.player.betAmount <= 999999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 100000)
      } else if (this.player.betAmount <= 9999999999) {
        this.player.betAmount = this.$math.add(this.player.betAmount, 1000000)
      }
      this.player.betAmount = parseInt(this.player.betAmount)
    },
    btnSubtract() {
      if (isBlank(this.player.betAmount) || this.player.betAmount <= this.game.betAmountRange.min) {
        Toast.text("Minimum of " + this.game.betAmountRange.min);
        return
      }
      
      if (this.player.betAmount <= 100) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 10)
      } else if (this.player.betAmount <= 1000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 100)
      } else if (this.player.betAmount <= 10000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 1000)
      } else if (this.player.betAmount <= 100000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 10000)
      } else if (this.player.betAmount <= 1000000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 100000)
      } else if (this.player.betAmount <= 10000000) {
        this.player.betAmount = this.$math.subtract(this.player.betAmount, 1000000)
      }
      this.player.betAmount = parseInt(this.player.betAmount)
    },
    //禁止拖拽
    noDragging(obj) {
      obj.hasBorders = false
      obj.hasControls = false
      obj.hasRotatingPoint = false
      obj.evented = false
    },
    //创建画布
    createCanvas() {
      this.draw.planeCenterRatio = this.$math
          .chain(this.draw.planeBeginTop)
          .subtract(this.draw.planeCenterTop)
          .divide(4)
          .multiply(0.01)
          .add(1)
          .done();
      this.draw.canvas = new fabric.Canvas("canvas", {width: 400, height: 250, backgroundColor: '#182342'});
    },
    //重置画布
    resetCanvas() {
      this.draw.canvas.remove(this.draw.plane)
      this.draw.canvas.clear();
      //this.draw.planeImg = require('../assets/images/fj.png');
      //this.draw.planeOpacity = 1;
      this.draw.plane = new fabric.Image(this.$refs.plane, {
        left: this.draw.planeBeginLeft,
        top: this.draw.planeBeginTop,
        angle: this.draw.planeBeginAngle
      });
      this.draw.plane.scale(this.draw.planeBeginScale);
      this.draw.background = new fabric.Image(this.$refs.background, {
        left: 0,
        top: this.draw.backgroundBeginTop,
      });
      this.draw.background2 = new fabric.Image(this.$refs.background2, {
        left: 0,
        top: this.draw.background2BeginTop,
      });
      this.noDragging(this.draw.plane)
      this.noDragging(this.draw.background)
      this.noDragging(this.draw.background2)
      /*
            this.draw.flightPath = new fabric.Path('C 10 90 160 130 308 25',{
              left: 0,
              top: 0,
              stroke: 'red',
              strokeWidth: 1,
              scaleY:3,
              fill:false,
              hasControls: false
            });
            //this.draw.canvas.add(this.draw.background, this.draw.background2, this.draw.plane, this.draw.leftSmoke, this.draw.rightSmoke, this.draw.flightPath);
      */
      this.draw.canvas.add(this.draw.background, this.draw.background2, this.draw.plane);
    },
    getUnitCount() {
      return this.$math.chain(this.game.currentRatio).subtract(1).multiply(100).done();
    },
    //炸弹
    planeBomb() {
      this.draw.plane.animate("opacity", "0", {
        duration: 500,
      })
      let imgUrl = require('../assets/images/fjc.png')
      fabric.Image.fromURL(imgUrl, (tImg) => {
        tImg.left = this.draw.planeUserLeft + 5
        tImg.top = this.draw.planeUserTop - 25
        tImg.scaleToWidth(30);
        tImg.scaleToHeight(60);
        this.draw.canvas.add(tImg)
        tImg.animate("top", "400", {
          duration: 1500,
        });
      })
      let imgUrl2 = require('../assets/images/fjbz.png')
      fabric.Image.fromURL(imgUrl2, (bImg) => {
        bImg.left = this.draw.planeUserLeft - 20
        bImg.top = this.draw.planeUserTop - 50
        bImg.scaleToWidth(100);
        bImg.scaleToHeight(100);
        //bImg.scale(1)
        this.draw.canvas.add(bImg)
        bImg.animate("opacity", "0", {
          duration: 1000,
        })
      })
      this.resetCanvas()
      this.draw.canvas.remove(this.draw.plane)
      this.draw.canvas.renderAll()
      
    },

    //逃跑
    getAway(img) {
      let a = require('../assets/images/' + this.imgUrl + 'avatar/' + img.slice(img.length-6))
      fabric.Image.fromURL(a, (oImg) => {
        oImg.left = this.draw.planeUserLeft + 4
        oImg.top = this.draw.planeUserTop + 20
        oImg.scaleToWidth(20);
        oImg.scaleToHeight(20);
        this.draw.canvas.add(oImg)
        let l = Math.ceil(Math.random() * 400)
        oImg.animate("top", "300", {
          duration: 2400 - l * 2,
        });
        oImg.animate("left", l, {
          duration: 2400 - l * 2,
        });
        // oImg.animate("width", 0, {
        //   duration: 2000,
        // });
        // oImg.animate("height", 0, {
        //   duration: 2000,
        // });
      })
    },
    //流畅模式 逃跑
    getAwaySmoothMode() {
      var round = new fabric.Circle({
        top : this.draw.planeUserTop - 5,
        left : this.draw.planeUserLeft + 10,
        radius : 5,
        fill : 'red',
        //stroke: 'orange',
        strokeWidth: 3 // 边框大小
      });
      round.animate("top", "385", {duration: 2300,});
      round.animate("left", "0", {duration: 2600,});
      this.draw.canvas.add(round)
    },

    //飞机动画
    renderPlane() {
      let unitCount = this.getUnitCount();

      let positionOffset = this.$math.chain(this.draw.planeUnit).multiply(unitCount).done();
      let positionOffset2 = this.$math.chain(this.draw.planeUnit - 3).multiply(unitCount).done();
      let positionOffset5 = this.$math.chain(this.draw.planeUnit * 5).multiply(unitCount).done();
      //let scaleOffset = this.$math.chain(0.002).multiply(unitCount).done();

      let currentTop = this.$math.chain(this.draw.planeBeginTop).subtract(positionOffset).done();
      let currentLeft = this.$math.chain(this.draw.planeBeginLeft).add(positionOffset5).done();
      let currentAngle = this.$math.chain(this.draw.planeBeginAngle).subtract(positionOffset2).done();
      //let currentScale = this.$math.chain(this.draw.planeBeginScale).add(scaleOffset).done();

      if (currentTop < this.draw.planeCenterTop) {
        currentTop = this.draw.planeCenterTop;
      }
      this.draw.plane.set({top: currentTop})
      this.draw.planeUserTop = currentTop;
      if (currentLeft < 30) {
        currentLeft = 30
      }
      if (currentLeft > this.draw.planeCenterLeft) {
        currentLeft = this.draw.planeCenterLeft;
        if (currentTop === this.draw.planeCenterTop) {
          let n = Math.round(Math.random() * -3 + 3);
          currentLeft = currentLeft + n
          currentTop = currentTop + n
        }
      }
      this.draw.plane.set({left: currentLeft});
      this.draw.planeUserLeft = currentLeft;

      if (currentAngle < this.draw.planeCenterAngle) {
        currentAngle = this.draw.planeCenterAngle;
      }
      this.draw.plane.set({angle: currentAngle});

      // if (currentScale > 0.2) {
      //   currentScale = 0.2;
      // }

      if(!this.smoothMode){
        if (this.draw.flame === 4) {
          this.draw.planeImg = require('../assets/images/'+ this.imgUrl +'fj.png');
          this.draw.flame++
        } else if (this.draw.flame === 8) {
          this.draw.planeImg = require('../assets/images/'+ this.imgUrl +'fj2.png');
          this.draw.flame = 0
        } else {
          this.draw.flame++
        }
      }
      //this.draw.plane.scale(currentScale);

    },
    //背景动画
    renderBackground() {
      //if (this.game.currentRatio < this.draw.planeCenterRatio) {
      //return;
      //}
      //计算偏移倍率
      //let unitCount = this.$math.chain(this.game.currentRatio).subtract(this.draw.planeCenterRatio).multiply(100).done();
      let unitCount = this.$math.chain(this.currentTimestamp() - this.game.startTimestamp).divide(50).done();

      let positionOffset3 = this.$math.chain(3).multiply(unitCount).done();
      let positionOffset5 = this.$math.chain(5).multiply(unitCount).done();
      let positionOffset20 = this.$math.chain(20).multiply(unitCount).done();

      let currentLeft2 = this.$math.chain(this.draw.background2BeginLeft).subtract(positionOffset20).done();
      let currentTop2 = this.$math.chain(this.draw.background2BeginTop).add(positionOffset5).done();

      if (currentLeft2 < this.draw.background2CenterLeft) {
        currentLeft2 = this.draw.background2CenterLeft;
      }
      this.draw.background2.set({left: currentLeft2});

      if (currentTop2 > this.draw.background2CenterTop) {
        currentTop2 = this.draw.background2CenterTop;
      }
      this.draw.background2.set({top: currentTop2});

      if (currentTop2 > this.draw.background2CenterTop - 200) {
        let currentTop = this.$math.chain(this.draw.backgroundBeginTop).add(positionOffset3).done();
        if (currentTop > 0) {
          currentTop = 0;
        }
        this.draw.background.set({top: currentTop});
      }
      
    },
    render() {
      this.renderPlane();
      this.renderBackground();
      this.draw.canvas.renderAll();
    },
    showEscapeAnimate() {
      let imgUrl3 = require('../assets/images/fjjb.png')
      fabric.Image.fromURL(imgUrl3, (j1Img) => {
        j1Img.left = this.draw.planeUserLeft - 20
        j1Img.top = this.draw.planeUserTop - 70
        j1Img.scaleToWidth(25);
        j1Img.scaleToHeight(25);
        this.draw.canvas.add(j1Img)
        j1Img.animate("top", 350, {duration: 1000,});
        j1Img.animate("left", 320, {duration: 1000,});
      })
      fabric.Image.fromURL(imgUrl3, (j2Img) => {
        j2Img.left = this.draw.planeUserLeft + 20
        j2Img.top = this.draw.planeUserTop - 20
        j2Img.scaleToWidth(25);
        j2Img.scaleToHeight(25);
        this.draw.canvas.add(j2Img)
        j2Img.animate("top", -50, {duration: 500,});
        j2Img.animate("left", 320, {duration: 500,});
      })
      fabric.Image.fromURL(imgUrl3, (j3Img) => {
        j3Img.left = this.draw.planeUserLeft - 10
        j3Img.top = this.draw.planeUserTop + 20
        j3Img.scaleToWidth(25);
        j3Img.scaleToHeight(25);
        this.draw.canvas.add(j3Img)
        j3Img.animate("top", -50, {duration: 800,});
        j3Img.animate("left", 400, {duration: 800,});
      })
      fabric.Image.fromURL(imgUrl3, (j4Img) => {
        j4Img.left = 130
        j4Img.top = 190
        j4Img.scaleToWidth(25);
        j4Img.scaleToHeight(25);
        this.draw.canvas.add(j4Img)
        j4Img.animate("top", -50, {duration: 800,});
        j4Img.animate("left", 400, {duration: 800,});
      })
      fabric.Image.fromURL(imgUrl3, (j5Img) => {
        j5Img.left = 50
        j5Img.top = 100
        j5Img.scaleToWidth(25);
        j5Img.scaleToHeight(25);
        this.draw.canvas.add(j5Img)
        j5Img.animate("top", -50, {duration: 800,});
        j5Img.animate("left", 400, {duration: 800,});
      })
      fabric.Image.fromURL(imgUrl3, (j6Img) => {
        j6Img.left = 50
        j6Img.top = 100
        j6Img.scaleToWidth(25);
        j6Img.scaleToHeight(25);
        this.draw.canvas.add(j6Img)
        j6Img.animate("top", -50, {duration: 800,});
        j6Img.animate("left", 400, {duration: 800,});
      })
      fabric.Image.fromURL(imgUrl3, (j7Img) => {
        j7Img.left = 100
        j7Img.top = 50
        j7Img.scaleToWidth(25);
        j7Img.scaleToHeight(25);
        this.draw.canvas.add(j7Img)
        j7Img.animate("top", -50, {duration: 800,});
        j7Img.animate("left", 400, {duration: 800,});
      })
    },
    betAmountMax(a) {
      if (a > 0) {
        this.player.betAmount = this.player.availableAmount
      }
      this.player.betAmount = this.player.betAmount > this.game.betAmountRange.max ? this.game.betAmountRange.max : this.player.betAmount
    },
    //恢复用户设置
    recoveryUserSettings() {
      let localAutoEscape = localStorage.getItem('autoEscape');
      if (isBlank(localAutoEscape)) {
        this.player.autoEscape = false;
      } else {
        this.player.autoEscape = localStorage.getItem('autoEscape') === 'true'
      }
      let localAutoEscapeRatio = localStorage.getItem('autoEscapeRatio');
      if (isBlank(localAutoEscapeRatio)) {
        this.player.autoEscapeRatio = 1.1;
      } else {
        this.player.autoEscapeRatio = parseFloat(localAutoEscapeRatio);
      }
    },

    //socket
    socketInit() {
      if (this.socket != null) {
        return;
      }
      this.socket = createSocket('crash2',this.bigwinData, this.broadcastMsgData);

      /**
       * 就绪
       */
      this.socket.on(commonEvent.ready, () => {
        this.socket.emit(commonEvent.join);
        this.resetCanvas();
        this.overlayShow = false
        
      });

      this.socket.on('disconnect', () => {
        this.game.on = false;
      });

      this.socket.on('pong', (latency) => {
        this.game.latency = latency + 'ms';
      });

      //初始化房间
      this.socket.on(this.eventEnum.initRoom, (room) => {
        //游戏阶段
        let stage = room.s;
        if (stage === this.stageEnum.flying) {
          this.game.on = true;
        } else {
          this.game.on = false;
        }
        if (stage === this.stageEnum.pause) {
          this.game.pause = true;
        }
        //下注阶段剩余时间
        let betRemainMillis = room.brm;
        if (isNotBlank(betRemainMillis)) {
          this.game.betDeadline = Date.now() + betRemainMillis;
          this.game.progressBar = ((this.game.betDeadline / 1000) - (Math.floor(Date.now() / 1000))).toFixed(1) * 10
          this.game.progressBarSetIntervalFn = setInterval(() => {
              if(this.game.progressBar > 1){
                this.game.progressBar -= 1
                //this.game.progressBarWidth = this.game.progressBar + '%'
                this.game.progressBarWidth = (Math.round(this.game.progressBar / 90 * 10000) / 100) - 10 + "%"
              }else{
                clearInterval(this.game.progressBarSetIntervalFn)
              }
            }
          ,100)
        }
        
        //结束阶段剩余时间
        let endRemainMillis = room.erm;
        if (isNotBlank(endRemainMillis)) {
          this.game.endDeadline = Date.now() + endRemainMillis;
        }
        this.game.serialNum = room.sn;
        this.game.currentRatio = room.r;
        this.game.stage = stage;
        this.game.startTimestamp = this.currentTimestamp() - room.sm;
        this.game.betAmountRange = room.bar;
        this.game.recommendBetAmounts = room.rba;
        this.game.fillUpAlertThreshold = room.fuat;
        this.game.fillUpAmount = room.fua;
        //设置下注框默认值
        this.player.betAmount = localStorage.getItem('lastBetAmount') ? localStorage.getItem('lastBetAmount') : this.game.betAmountRange.min
        //最近几局倍率
        this.game.gameHistoryList = room.hl;
        //上局游戏赢家
        room.lgbw.forEach(bigWinner => {
          this.game.lastGameBigWinners.push(bigWinner.m + ' won ' + bigWinner.wa);
        });
        this.game.lastGameTopList = room.lgbw
        this.game.lastGameWinAmount = room.lgpwa
        //总玩家人数
        this.game.totalPlayerCount = room.tpc;
        //玩家列表
        this.game.players.length = 0;
        this.game.playersMore = false;
        room.p.forEach(player => {
          this.game.players.unshift({
            id: player.id,
            //avatar: player.a,
            avatar: require('../assets/images/' + this.imgUrl + 'avatar/' + player.a.slice(player.a.length-6)),
            mobile: player.m,
            betAmount: player.ba,
            escapeRatio: getValue(player.er),
            escapeAmount: getValue(player.ea),
          });
          if (this.game.players.length >= 20) {
            this.game.playersMore = true
          }
          if (isNotBlank(player.er)) {
            //已经逃跑
            this.game.escapedPlayerCount++;
          }
        });


      });

      //当前用户玩家信息
      this.socket.on(this.eventEnum.playerInfo, (playerInfo) => {
        //可用余额
        this.player.id = playerInfo.id
        this.player.vipLevel = playerInfo.vl;
        this.player.vipMaxRatio = playerInfo.vmr;
        this.player.avatar = playerInfo.a
        this.player.availableAmount = playerInfo.aa
        this.player.rechargeAmount = playerInfo.ra
        this.player.withdrawAmount = playerInfo.wa
        this.player.playStatus = playerInfo.ps;
        if (isNotBlank(getValue(playerInfo.ba))) {
          this.player.betAmount = getValue(playerInfo.ba);
        }
        this.player.escapeRatio = getValue(playerInfo.er);
        this.player.escapeAmount = getValue(playerInfo.ea);
        if (isTrue(playerInfo.nw)) {
          this.showNeedWithdrawDialog(this.$t('game.pleaseWithdraw'));
        }
        this.showFillUpTipsIfSuitable();
      });

      //开始下注阶段
      this.socket.on(this.eventEnum.startBet, (info) => {
        this.game.stage = this.stageEnum.bet;
        this.game.pause = false;
        this.game.on = false;

        //刷新玩家列表
        this.game.playersMore = false;
        this.game.totalPlayerCount = 0;
        this.game.players.length = 0;
        this.game.escapedPlayerCount = 0;

        this.game.serialNum = info.sn;
        this.game.betDeadline = Date.now() + info.btd * 1000;

        this.game.progressBar = ((this.game.betDeadline / 1000) - (Math.floor(Date.now() / 1000))).toFixed(1) * 10
        this.game.progressBarSetIntervalFn = setInterval(() => {
            if(this.game.progressBar > 1){
              this.game.progressBar -= 1
              //this.game.progressBarWidth = this.game.progressBar + '%'
              this.game.progressBarWidth = (Math.round(this.game.progressBar / 90 * 10000) / 100) - 10 + "%"
            }else{
              clearInterval(this.game.progressBarSetIntervalFn)
            }
          }
        ,100)

        //下注状态重置图像位置
        this.resetCanvas();
        //重置玩家状态
        this.player.playStatus = this.playerStatusEnum.notBet;
        this.player.myWinAmount = null
        this.player.betAmount2 = null
      });

      //开始飞行阶段
      this.socket.on(this.eventEnum.startFlying, () => {
        this.game.stage = this.stageEnum.flying;
        this.game.pause = false;
        this.game.on = true;
        this.showViponly = false
        //统一使用前端的时间戳
        this.game.startTimestamp = this.currentTimestamp();
        // this.game.expect = aesDecrypt(info.t);
      });

      // 开始结束阶段
      this.socket.on(this.eventEnum.startEnd, (info) => {
        if (this.game.stage === this.stageEnum.end) {
          return;
        }
        this.game.stage = this.stageEnum.end;
        this.game.pause = false;
        this.game.on = false;
        //this.game.playersMore = false;

        //炸弹
        this.planeBomb();
        this.game.endDeadline = Date.now() + info.edd * 1000;
        //刷新最近几局倍率
        this.game.gameHistoryList = info.hl;
        //刷新上局游戏赢家
        this.game.lastGameBigWinners.length = 0;
        info.lgbw.forEach(bigWinner => {
          this.game.lastGameBigWinners.push(bigWinner.m + ' won ' + bigWinner.wa);
        });
        this.game.lastGameTopList = info.lgbw
        this.game.lastGameWinAmount = info.lgpwa
        this.game.endRatio = info.cr
        this.showFillUpTipsIfSuitable();

      });

      //任意用户下注通知
      this.socket.on(this.eventEnum.betNotify, (notify) => {
        this.game.totalPlayerCount = notify.tpc;
        if (this.game.players.length >= 20) {
          this.game.players.pop()
          this.game.playersMore = true
        }
        if (this.game.players.length < 20) {
          this.game.players.unshift({
            id: notify.id,
            //avatar: notify.a,
            avatar: require('../assets/images/' + this.imgUrl + 'avatar/' + notify.a.slice(notify.a.length-6)),
            mobile: notify.m,
            betAmount: notify.ba,
            escapeRatio: null,
            escapeAmount: null,
          });
        }
      });

      //任意用户逃离通知
      this.socket.on(this.eventEnum.escapeNotify, (notify) => {
        //逃离效果
        if(this.smoothMode){
          this.getAwaySmoothMode();
        }else{
          this.getAway(notify.a);
        }
        //列表更新
        this.game.players.forEach(player => {
          if (player.id === notify.id) {
            player.escapeRatio = notify.er;
            player.escapeAmount = notify.ea;
          }
        });
        //逃离用户数+1
        this.game.escapedPlayerCount++;
      });
    },
    nextRound() {
      Toast.text(this.$t('game.waitForTheNextRound'));
    },
    bet() {
      if (isBlank(this.player.betAmount) && this.betDisabled) {
        return;
      }
      
      if (this.player.betAmount > this.game.betAmountRange.min && this.player.vipLevel < 1) {
        //Vip0 input amount can only be 10
        Toast.text(this.$t('game.info3') + this.game.betAmountRange.min);
        this.player.betAmount = this.game.betAmountRange.min
        return;
      }
      this.betDisabled = true
      axios.post("/api/crash2/bet",
          {
            betAmount: this.player.betAmount
          },
          { timeout: 8000 }
      ).then((res) => {
        this.player.myWinAmount = 0
        let result = res.data;
        if (result.code === 0) {
          Toast.success(this.$t('global.successfully') + ' ' + this.player.betAmount);
          localStorage.setItem("lastBetAmount", this.player.betAmount);
          this.player.availableAmount = result.data.availableAmount;
          /*
                    let setIntervalFn = setInterval(
                      function(){
                        if(this.player.availableAmount > ack.aa){
                          this.player.availableAmount -= 10
                        }else{
                          this.player.availableAmount = ack.aa;
                          clearInterval(setIntervalFn)
                        }
                      }
                    ,50)
          */
          this.player.playStatus = this.playerStatusEnum.bet;
          this.player.betAmount2 = this.player.betAmount;
        } else if (result.bizCode === 'CRASH_WAIT_NEXT_ROUND') {
          //已经是飞行阶段了
          Toast.text(this.$t('game.waitForTheNextRound'));
        } else if (result.bizCode === 'GAME_BET_NEED_WITHDRAW') {
          //需要提现
          this.showNeedWithdrawDialog(this.$t('game.pleaseWithdraw'))
        }
        this.betDisabled = false
      }).catch(() => {
        this.betDisabled = false
      });
    },
    escape(autoEscape) {
      if(this.escapeDisabled){
        return
      }
      this.escapeDisabled = true
      axios({
        url: "/api/crash2/escape",
        method: "post",
        timeout: 8000
      }).then((res) => {
        let result = res.data;
        let data = result.data;
        if (result.code === 0) {
          
          this.showEscapeAnimate();

          // Toast.success('Win ' + ack.ea + ' (' + ack.er + "X)");
          this.player.availableAmount = data.availableAmount;
          this.player.playStatus = this.playerStatusEnum.escape;
          this.player.escapeRatio = data.escapeRatio;
          this.player.escapeAmount = data.escapeAmount;
          this.player.myWinAmount = data.escapeAmount;
          if (isTrue(data.ratioLimited)) {
            //被限制了最高倍率
            this.showMaxRatioAlert();
          }
          if (isTrue(data.needWithdraw)) {
            //需要提现
            this.showNeedWithdrawDialog();
          }
        } else if (result.bizCode === 'CRASH_ALREADY_CRASH') {
          //已经爆炸了
          Toast.fail('already crashed!');
        } else {
          Toast.fail(result.msg);
        }
        if (autoEscape) {
          this.player.autoEscaping = false;
        }
        this.escapeDisabled = false
      }).catch(() => {
        this.escapeDisabled = false
      });
    },
    loadHistory() {
      axios({
        url: "/api/crash2/stats",
        method: "get"
      }).then((res) => {
        let response = res.data;
        let data = response.data;

        //刷新最近几局倍率
        this.game.gameHistoryList = data.hl;
        //刷新上局游戏赢家
        this.game.lastGameBigWinners.length = 0;
        data.lgbw.forEach(bigWinner => {
          this.game.lastGameBigWinners.push(bigWinner.m + ' won ' + bigWinner.wa);
        });
        this.game.lastGameTopList = data.lgbw
        this.game.lastGameWinAmount = data.lgpwa
        this.game.statistics = false
      });
    },
    /**
     * 到达卡点金额，强制提现
     */
    showNeedWithdrawDialog() {
      //if(this.player.rechargeAmount > 0){this.gotitBtn = true}
      this.withdrawalPopup = true
    },
    /**
     * 提示邀请补满
     */
    showFillUpTipsIfSuitable() {
      if (this.player.rechargeAmount === 0 && this.player.availableAmount <= this.game.fillUpAlertThreshold) {
        //未充值过且钱包余额不足
        if (this.showDialogFill) {
          this.showDialogFill = false
          setTimeout(() => {
            this.invitePopup = true
          }, 3000)
        }
      }
    },
    showMaxRatioAlert() {
      if (this.alreadyAlertMaxLimit) {
        return;
      }
      Dialog({
        //Congratulations for big win!
        title: this.$t('game.DialogTitle'),
        //'For vip x , your max stop ratio is 100x, you can level-up vip to unlock the limit.',
        content: this.$t('game.DialogContent1') + this.player.vipLevel + this.$t('game.DialogContent2') + this.player.vipMaxRatio + this.$t('game.DialogContent3'),
        okText: this.$t('game.gotitBtn'),  
        noCancelBtn: true
      });
      this.alreadyAlertMaxLimit = true;
    },

    withdrawClick() {
      router.push('/withdrawalBalance?amountType=coin');
    },
    inviteClick() {
      router.push('/agentPromotion');
    },
    rechargeBtnClick(){
      if(this.commonInfo.firstRechargeAvailable){
        router.push('/firstRechargeBonus');
      }else{
        router.push('/rechargeBalance');
      }
    },
    currentTimestamp() {
      return new Date().getTime();
    },
    updateAutoEscape() {
      if (this.player.autoEscapeRatio === null || this.player.autoEscapeRatio === 'NaN' || this.player.autoEscapeRatio < 1.1) {
        this.player.autoEscapeRatio = 1.1
      }
      localStorage.setItem('autoEscape', this.player.autoEscape)
      localStorage.setItem('autoEscapeRatio', this.player.autoEscapeRatio)
    },
    tryAutoEscape() {
      if (!this.player.autoEscape) {
        return;
      }
      if (this.player.playStatus !== this.playerStatusEnum.bet) {
        return;
      }
      if (this.player.autoEscaping) {
        //正在逃跑中
        return;
      }
      if (this.game.currentRatio >= this.player.autoEscapeRatio) {
        //自动逃离
        this.player.autoEscaping = true;
        this.escape(true);
      }
    },
    getGameHistoryClass(history, index) {
      let clas = '';
      if (index === 0) {
        clas = 'bg0'
      }
      if (history.l === 1) {
        return 'bg1' + ' ' + clas;
      } else if (history.l === 2) {
        return 'bg2' + ' ' + clas;
      } else if (history.l === 3) {
        return 'bg3' + ' ' + clas;
      } else if (history.l === 4) {
        return 'bg4' + ' ' + clas;
      } else {
        return 'bg5' + ' ' + clas;
      }
    },
    iconClick() {
      router.push({
        name: "myPlayList",
      });
    },
    askClick() {
      this.rulePopup = true
    },
    async initPage() {
      const deviceId = await getDeviceId();
      localStorage.setItem("di", deviceId);
      return axios.get("/api/crash2/visit", {})
    },
    getMyHistoryList() {
      axios({
        url: "/api/crash2/order/paginate",
        method: "get",
        params: {current: 1}
      }).then((res) => {
        this.myHistoryData = res.data.data.records
        if (this.myHistoryData.length > 0) {
          this.listNoData = false;
        }
      });
    },
    playersList() {
      this.tabActive = true
    },
    myHistoryList() {
      this.tabActive = false
      this.getMyHistoryList();
    },
    getCurrentMillis() {
      return this.currentTimestamp() - this.game.startTimestamp;
    },
    calculateRatio(startRatio, millis, subtract, coefficient) {
      return this.$math.format(this.$math.add(this.$math.bignumber(startRatio), this.$math.multiply(this.$math.bignumber(millis - subtract), this.$math.bignumber(coefficient))), (value) => value.toFixed(2));
    },
    doOnMounted() {
      this.socketInit();
      this.createCanvas();
      this.recoveryUserSettings();
      setInterval(() => {
        if (this.game.on) {
          const millis = this.getCurrentMillis();
          if (millis >= 0 && millis < 10000) {
            this.game.currentRatio = this.calculateRatio(1.00, millis, 0, 0.0002);
          } else if (millis >= 10000 && millis < 20000) {
            this.game.currentRatio = this.calculateRatio(3.00, millis, 10000, 0.0004);
          } else if (millis >= 20000 && millis < 30000) {
            this.game.currentRatio = this.calculateRatio(7.00, millis, 20000, 0.0008);
          } else if (millis >= 30000 && millis < 40000) {
            this.game.currentRatio = this.calculateRatio(15.00, millis, 30000, 0.0016);
          } else if (millis >= 40000 && millis < 50000) {
            this.game.currentRatio = this.calculateRatio(31.00, millis, 40000, 0.0032);
          } else {
            this.game.currentRatio = this.calculateRatio(63.00, millis, 50000, 0.0064);
          }
          // this.tryForceCrash();
          //if (this.player.playStatus === this.playerStatusEnum.bet) {
            this.player.currentWinAmount = this.$math.format(this.$math.multiply(this.$math.bignumber(this.player.betAmount), this.$math.bignumber(this.game.currentRatio)), (value) => value.toFixed(2));
          //}
          this.render();
          this.tryAutoEscape();
          this.game.delay = 0
        } else if (this.game.delay < 25 && this.game.delay != -1) {
          this.renderPlane();
          this.draw.canvas.renderAll();
          this.game.delay++
        }
        if (this.game.currentRatio > (Number(this.player.vipMaxRatio) - 0.5) && this.game.currentRatio < (Number(this.player.vipMaxRatio) + 0.5) && !this.showViponly) {
          this.showViponly = true
          //this.viponlyFn()
        }
      }, 50);
    },
    smoothModeToggle() {
      this.smoothMode = !this.smoothMode
      localStorage.setItem("smoothMode", this.smoothMode);
    },
    downloadApp(){
      window.open(getCommonInfoAttr('appDownloadUrl'))
    }
  },
  mounted() {
    if(document.querySelector('body').getAttribute('s') === 's2'){
      this.overlayClass =  'overlay-div overlay-bg0'
    }
    if(localStorage.getItem('smoothMode') === 'true') {
      this.smoothMode = localStorage.getItem('smoothMode')
    }
    this.initPage().then((response) => {
      if(isNotBlank(response.data.data.appTaskStatus)){
        this.appTaskReward = response.data.data.appTaskReward
        this.appTaskStatus = response.data.data.appTaskStatus
      }
      if (isNotBlank(response.data.data.vtoken)) {
        localStorage.setItem("vtoken", response.data.data.vtoken);
      }
      this.doOnMounted();
    });
    setLastTimeGameName('crash')
  },
  beforeRouteLeave() {
    //断开游戏socket
    disconnectSocket(gameType.CRASH, this.socket);
  },
  //watch: {
  //  'game.gameHistoryList' : function(){}
  //}
};
</script>

<style scoped>
.overlay-div {opacity: 1;}
.overlay-bg1{background: #000 url(../assets/images/fje.gif) center 20% no-repeat !important;
  background-size: 179px 168px !important;}
.nut-button--round {
  border-radius: 5px;
}

.games-main {
  padding-top: 20px;
  background: #1c092c;
  padding: 15px 10px 90px 10px;
  max-width: 100%;
  min-width: 350px;
  overflow: hidden;
  margin: 0 auto;
}

.g-vm {
  background: #260f37;
  height: 305px;
  border-radius: 10px;
  border: 1px solid #42255e;
  margin-bottom: 10px;
  position: relative;
  max-width: 100%;
  margin: 0 auto 10px auto;
}

.g-vm .top {
  background: #261037;
  height: 38px;
  border-bottom: 1px solid #42255e;
  padding-top: 7px;
  display: flex;
  justify-content: left;
}

.g-vm .top span {
  line-height: 30px;
  height: 30px;
  padding: 0px 7px;
  color: #ffeb09;
  margin-left: 6px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #0b3b15;
  width: 20%;
  text-align: center;
}

.g-vm .top span:nth-child(0) {
  width: 1px !important;
}
/* 
.g-vm .top span.bg1 {
  background: #f63a3a;
}

.g-vm .top span.bg2 {
  background: #f7bb47;
}

.g-vm .top span.bg3 {
  background: #208f0a
}

.g-vm .top span.bg4 {
  background: #2988f6;
}

.g-vm .top span.bg5 {
  background: #7854c0;
} */

.g-vm .top span.bg0 {

  text-align: center;
  overflow: hidden;
  animation: widthMove 1s ease-in-out infinite;
  -webkit-animation: widthMove 1s ease-in-out infinite;; /*Safari and Chrome*/
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1; /*Safari and Chrome*/
}

@keyframes widthMove {
  0% {
    width: 0px;
  }
  100% {
    width: 50px;
  }
}

@-webkit-keyframes widthMove /*Safari and Chrome*/
{
  0% {
    width: 1px;
  }
  100% {
    width: 50px;
  }
}

.g-vm .top .nut-icon {
  margin-top: 5px;
  margin-left: 10px;
  padding-right: 20px;
  vertical-align: middle;
  margin-left: auto;
}
  
.g-vm .top-left {
  position: absolute;
  padding: 5px 10px 5px 6px;
  border-radius:0 0 20px 0;
  left: 0px;
  top: 50px;
  color: #fff;
  z-index: 101;
  font-size: 10px;
  opacity: .5;
  background: url(../assets/images/bg66.png);
}
.g-vm .top-left .f12{ font-size: 10px;}
.g-vm .bottom-left {
  position: absolute;
  left: 15px;
  bottom: 5px;
  color: #f1d242;
}
.g-vm .bottom-center {
  position: absolute;
  right: 0;
  bottom: 0px;
  color: #f1d242;
  text-align: left; line-height: 32px;height: 30px;
}
.g-vm .top-left strong{ font-size: 10px;}
.g-vm .top-left strong.r{color:#f63a3a}
.g-vm .top-left strong.g{color:#208f0a}

.g-vm .top-right {
  position: absolute;
  right: 5px;
  bottom: 7px;
  color: #fff;
  opacity: .8;
}

.g-vm .top-right span {
  color: #fff;
  margin-left: 5px;
}

.g-vm .top-right .c-g {
  color: rgb(0, 180, 30);
  padding-left: 30px;
  background: url(../assets/images/wifi-g.png) center center no-repeat;
  background-size: 22px auto;
}

.g-vm .top-right .c-o {
  color: rgb(196, 176, 0);
  padding-left: 30px;
  background: url(../assets/images/wifi-o.png) center center no-repeat;
  background-size: 22px auto;
}

.g-vm .top-right .c-r {
  color: rgb(236, 67, 0);
  padding-left: 30px;
  background: url(../assets/images/wifi-r.png) center center no-repeat;
  background-size: 22px auto;
}

.state1-preparing {
  background: none;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 45px;
  bottom: 0;
}

.state1-preparing.typebg0 {
  background: #000 url(../assets/images/fjf.png) center center no-repeat;
  background-size: 110px auto;
}

.state1-preparing.typebg1 {
  background: url(../assets/images/fjd.gif) center 200px no-repeat;
  background-size: 40px auto;
}

.state1-preparing.typebg2 {
  background: url(../assets/images/bg66.png) 100% 100% repeat;
  height: 370px;
}

.state1-preparing.typebg2-my {
  background: #000 url(../assets/images/bg66.gif) 100% 100% no-repeat;
  height: 370px;
  animation: 3s opacity2 0s 1;
  -webkit-animation: 3s opacity2 0s 1;
  -moz-animation: 3s opacity2 0s 1;
}

@keyframes opacity2 {
  0% {
    opacity: 0
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes opacity2 {
  0% {
    opacity: 0
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes opacity2 {
  0% {
    opacity: 0
  }
  50% {
    opacity: .8;
  }
  100% {
    opacity: 1;
  }
}

.state1-preparing.typebg3 {
  background: none;
}

.state1-preparing h3 {
  color: #ffcb7a;
  padding-top: 10px;
  font-size: 30px;
  font-weight: 400;
  margin: 0;
  /*text-shadow: 0px 2px 5px #000;*/
}

.state1-preparing p {
  color: #fff;
  font-size: 20px;
}
.state1-preparing.typebg1 h3{
  height: 30px; line-height: 30px; opacity: .5; color: #ffcb7a !important;
}
.state1-preparing.typebg1 h3, .state1-preparing.typebg0 h3 {
  color: #fff;
}

.state1-preparing.typebg1 p, .state1-preparing.typebg0 p {
  color: #fff;margin-bottom: 0 !important;
}

.state1-preparing .nut-countdown {
  display: inline;
  color: #ffcb7a;
}

.state1-preparing .top-user {
  margin: 10px 20px 0 20px;
}

.state1-preparing .top-user li {
  height: 20px;
  margin-top: 3px;
  display: table-cell;
}

.state1-preparing .top-user li span {
  line-height: 20px;
  color: #fff;
  margin-left: 10px;
}

.state1-preparing .top-user li img {
  width: 18px;
  height: 20px;
  vertical-align: middle;
}

.state1-preparing .top-user .top1 p {
  margin: 5px 0;
}

.state1-preparing .top-user .top1 p.img {
  padding-top: 20px;
  background: url(../assets/images/bg58.png) center top no-repeat;
  background-size: 40px auto;
}

.state1-preparing .top-user p {
  font-size: 18px;
  margin: 0;
}

.state1-preparing .top-user p strong {
  font-size: 34px;
  color: #f1d242;
  display: block;
  font-weight: 500;
}

.state1-preparing .top-user .top1 p strong {
  color: #f63a3a;
}

.state1-preparing .top-user .top1 p.img img {
  width: 40px;
  height: 40px;
  border-radius: 24px;
  border: 2px solid #d9b444;
}


/* 新结算 */

.state1-preparing .top-user-new h5{
  height: 70px; line-height: 70px;
  background: url(../assets/images/fjc.png) 20px center no-repeat;
  background-size: 40px auto;
  color: #fff; font-size: 30px; text-align: left; margin: 0;
  text-align: center;
}
.state1-preparing .top-user-new h5 strong{ color: #f6e33a; font-size: 50px;}
.state1-preparing .top-user-new {
  margin: 30px 5px 0 10px;
}
.state1-preparing .top-user-new li {
  height: 40px;
  line-height: 40px;
  margin-top: 0px;
  text-align: left;
}
.state1-preparing .top-user-new li:nth-child(1){
  background: url(../assets/images/abg-2.png) 0 0 no-repeat;
  background-size: 40px auto;
}
.state1-preparing .top-user-new li:nth-child(2){
  background: url(../assets/images/abg-1.png) 0 0 no-repeat;
  background-size: 40px auto;
}
.state1-preparing .top-user-new li:nth-child(3){
  background: url(../assets/images/abg-1b.png) 0 0 no-repeat;
  background-size: 40px auto;
}
.state1-preparing .top-user-new li span {
  line-height: 40px;
  color: #fff;
  margin-left: 5px;
  display: inline-block;
  width: 60px;
  font-size:15px;
}

.state1-preparing .top-user-new li span.g{ width: 48px; color: #39ff7f;}
.state1-preparing .top-user-new li span.x{ width: 60px; color: #ec560c;}
.state1-preparing .top-user-new li span.r{ width: 130px; color: #f8e811;}


.state1-preparing .top-user-new li strong{
  height: 40px;
  line-height: 40px;
  color: #fff;
}
.state1-preparing .top-user-new li img {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  margin-right: 10px;
  vertical-align: middle;
}
.state1-preparing .top-user-new p{
    background: #022d0a;
    border-radius: 5px; padding: 5px 0; opacity: 5;
  }

  .bottom-center .c-y{ background: #062a01; border-radius:5px 0 0 0; padding: 5px 10px;}
/* 新结算 end */





.g-operate {
  background: #351340;
  border-radius: 10px;
  padding: 5px 10px 10px 10px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.g-operate h4 {
  color: #a586aa;
  margin: 0 0 0 0 !important;
  padding: 0px;
  height: 38px;
  line-height: 38px;
  font-weight: 400;
  margin-bottom: 5px;
  text-align: right;
  overflow: hidden;
  width: 100% !important;
  min-width: 380px !important;
}
.g-operate .g-amount-r h4 { width: 100% !important;}

.g-operate h4 .tx span {
  color: #f1d242;
  background: url(../assets/images/fjjb.png) left center no-repeat;
  background-size: 18px auto;
  padding-left: 23px;
}

.g-operate h4:last-child {
  height: 40px;
  width: 100%;
  text-align: left;
}

.g-operate h4 .r {
  float: none;
  height: 32px;
  line-height: 32px;
}

.g-operate h4:last-child .r {
  margin-left: 20px;
}

.g-operate h4:last-child .r2 {
  margin-left: 0px;
  float: right;
}

.g-amount-r .tx {
  font-weight: 300 !important;
  font-size: 20px;
}

.g-operate h4 .l {
  margin-right: 20px
}

.g-operate h4 .r strong, .g-operate h4 .l strong {
  color: #f1d242;
  font-weight: 400;
  vertical-align: middle;
}

.g-operate .nut-switch.nut-switch-base {
  margin-top: 6px;
  background-color: #005200;
}

.g-operate .nut-switch.switch-close {
  background-color: #a69da8;
}

.g-operate .nut-inputnumber {
  display: inline !important;
  vertical-align: middle;
}
.mt20 .g-operate .nut-inputnumber {
  display: inline !important;
}

.g-amount {
  margin-top: 10px;
  height: 140px;
  width: 100%
}

.g-amount-r {
  float: right;
  width: 40%;
  text-align: right;
}

.g-amount-r .nut-button--round {
  height: 81px;
  font-size: 25px !important; 
}
.vn .g-amount-r .nut-button--round {font-size: 16px !important;}

.g-amount-r .btn-bg-g {
  background: #208f0a !important;
}

.g-amount-r .btn-bg-o {
  background: #f63a3a !important;
}

.g-amount-r .btn-bg-d {
  background: #9cb597 !important;
}

.g-amount-r .btn-bg-d span {
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.g-amount .inpbox {
  background: #16001a;
  border-radius: 10px;
  height: 36px;
  outline: none;
  position: relative;
}

.g-amount .inpbox .btn-bet {
  position: absolute;
  right: 2px;
  top: 30x;
  width: 40px;
  height: 26px;
  line-height: 26px;
  background: none;
  border: 0;
  color: #f1d242;
  font-size: 30px;
}

.g-amount .inpbox .btn-sub {
  left: 5px;
  top: 28x;
  font-size: 56px;
  font-weight: 300;
}

.g-amount .inpbox .btn-plus {
  height: 36px;
  line-height: 36px;
  font-weight: 600;
}

.g-amount input:focus {
  outline: none;
}

.g-amount .inpbox input {
  text-align: center;
  width: calc(100% - 120px);
  font-size: 22px;
  line-height: 34px;
  border: 0;
  margin-left: 60px;
  background: transparent;
  color: #f1d242;
}

.g-amount .inpbox .r {
  float: right;
  margin-top: 5px;
}

.g-amount .inpbox .r .nut-button--square {
  background: none !important;
}

.g-amount .inpbox .r .tx {
  color: #fff;
  line-height: 26px;
  margin-right: 10px;
  opacity: .4;
  vertical-align: middle;
  float: right;
}

.g-amount .inpbox .r .tx strong {
  font-weight: 300 !important;
}

.g-amount .inpbox .nut-button {
  background: none;
  border: 0;
  padding: 0px 6px;
}

.g-amount .btnbox {
  display: flex;
  justify-content: space-between;
  text-align:justify;
  flex-wrap: wrap;
  padding-top: 10px;
}

.g-amount .btnbox .nut-button {
  height: 35px !important;
  font-size: 16px !important;
  margin: 0 0 10px 0;
  width: 32%;
  background: #5c2766 !important;
  color: #FFF;
}

.g-amount .btnbox .nut-button:last-child {
  background: #e7b03a !important;
}
.vn .g-amount .btnbox .nut-button {font-size: 14px !important;}
.g-players {
  min-height: 250px;
}

.g-players h4 {
  color: #a586aa;
  margin: 0;
  padding: 0;
  line-height: 30px;
  height: 30px;
  overflow: hidden;
  font-weight: 400;
  margin-bottom: 1px;
  vertical-align: middle;
}

.g-players h4 span {
  color: #fff;
}

.g-players h4 .nut-icon {
  vertical-align: middle;
}

.g-players h4 strong {
  float: left;
  font-weight: 300;
  width: 130px;
  padding: 0 0 0 10px;
  border-radius: 5px 30px 0 0;
}

.g-players h4 strong.active {
  background: #310f38;
  font-weight: 500;
}

.g-players h4 strong:last-child {
  float: right;
  text-align: right;
  width: 140px;
}

.g-players h4 strong:last-child.active {
  background: #310f38;
  font-weight: 500;
  border-radius: 30px 5px 0 0;
}

.g-players li {
  background: #310f38;
  margin-bottom: 2px;
  border-radius: 5px;
  line-height: 22px;
  height: 22px;
  overflow: hidden;
  color: #a586aa;
  font-size: 11px;
  vertical-align: middle;
  text-align: left;
}

.g-players li.myBet {
  background: #e7b03a;
}

.g-players li .l {
  padding-left: 8px;
  float: left;
  display: block;
  overflow: hidden;
}

.g-players li span {
  display: block;
}

.g-players li .l img {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-right: 3px;
  margin-bottom: 3px;
}

.g-players .mylist li .l:nth-child(1) {
  width: 120px !important;
}

.g-players li .r {
  padding-right: 10px;
  text-align: right;
}

.canvas-main {
  position: relative;
  height: 255px !important;
  width: 100%;
  overflow: hidden !important;
  margin: 0 auto;
  background: #000;
}

.rocket-data {
  position: absolute;
  left: calc(50% - 90px);
  top: 30px;
  text-align: left;
  color: #f6e33a;
  font-size: 40px;
  font-weight: 500;
  line-height: 30px;
  /*text-shadow: 0px 0px 3px rgb(66, 39, 39);*/
}

.rocket-data .rocket-height {
  font-size: 50px;
  color: #c3065b;
  font-weight: 600;
  /*text-shadow: 2px 2px 3px #310f38;*/
}

.rocket-data .nut-countup {
  display: inline !important;
}

.rocket-data span {
  line-height: 30px;
}

.rocket-data strong.bg1 {
  color: #f63a3a;
}

.rocket-data strong.bg2 {
  color: #f7bb47;
}

.rocket-data strong.bg3 {
  color: #208f0a;
}

.rocket-data strong.bg4 {
  color: #0c9771;
}

.rocket-data strong.bg5 {
  color: #2988f6;
}

.rocket-data strong {
  font-size: 70px;
  margin-left: 5px;
  font-weight: 600;
  width: 100px;
  font-family: 'Kanit','sans-serif' !important;
}

.rocket-data span:last-child {
  margin-top: 10px;
  background: url(../assets/images/fjjb.png) 0 7px no-repeat;
  background-size: 25px 25px;
  padding-left: 30px;
  margin-top: 10px;
  line-height: 40px;
  font-size: 30px;
  color: #fec732;
}

#canvas {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  background: none;
  height: 250px !important;
  width: 350px;
  overflow: hidden !important;
}

#canvas-img1 {
  opacity: 0;
}
</style>

<style>


.games-main .canvas-container {
  border-radius: 8px;
  margin: 5px auto 5px auto !important;
}

.games-main .upper-canvas {
  border-radius: 8px;
  height: 250px !important;
  overflow: hidden !important;
}

.games-main #canvas {
  border-radius: 8px;
  height: 250px !important;
  overflow: hidden !important;
}

.g-operate h4 .nutui-iconfont {
  background: none;
  color: #a586aa !important;
  vertical-align: sub;
  font-size: 34px;
}

.g-operate h4 .nut-inputnumber input {
  height: 30px;
  line-height: 30px;
  background: #16001a;
  color: #fff;
  font-size: 18px;
  width: 80px;
  display: inline !important;
}

.g-amount .inpbox .r .nutui-iconfont {
  color: #a69da8;
}

.g-amount .inpbox .r .nut-button {
  color: #a69da8;
}

.nut-noticebar-page {
  height: 30px !important;
  font-size: 12px !important;
  border-radius: 5px;
  opacity: 0.5;
}

.nut-noticebar-vertical {
  height: 30px !important;
  font-size: 12px !important;
  border-radius: 5px;
  opacity: 0.5;
}

.nut-noticebar-vertical .horseLamp_list .horseLamp_list_item {
  height: 30px !important;
}

.nut-noticebar-vertical .horseLamp_list li {
  padding-left: 20px;
}

.nut-noticebar-page .left-icon {
  margin: 0 5px 0 5px !important;
}

.pop-withdrawal .hd {
  text-align: center;
  font-size: 18px;
  color: #002710;
  line-height: 40px;
}

.pop-withdrawal .bd {
  text-align: left;
  color: #666;
}

.pop-withdrawal .btn, .pop-invite .btn {
  text-align: center;
  padding: 20px 20px 0 20px;
  margin: 0 20px;
}

.pop-withdrawal .btn .nut-button--round{
  width: 100%; margin-bottom: 10px;
}
.pop-withdrawal .btn .link{ color: #0c3901;}

.pop-invite .hd {
  text-align: center;
  font-size: 18px;
  color: #002710;
  line-height: 40px;
}

.pop-invite .bd {
  text-align: center;
  color: #666;
}

.pop-invite .bd strong {
  font-size: 25px;
  color: #fa7a27;
  background-size: 20px auto;
}

.smooth-main.pt40{ padding: 0 !important;}
.smooth-main .nut-tabbar-item:nth-child(3){opacity: 0;}
.smooth-main .nut-navbar{display: none;}
.smooth-main .nut-tabbar{ height: 50px !important;}
.smooth-main .nut-tabbar .nut-tabbar-item_icon-box_nav-word{ display: none !important;}
.smooth-main .nut-tabbar .nut-tabbar-item_icon-box {padding: 0 0 8px 0 !important;}

.top-betbtn{ display: block ; width: 100%;}

.top-betbtn .nut-button--round {
  height: 60px;
  font-size: 26px !important;
}

.top-betbtn .btn-bg-g {
  background: #e7b03a !important;
}

.top-betbtn .btn-bg-o {
  background: #f63a3a !important;
}

.top-betbtn .btn-bg-d {
  background: #acb597 !important;
}

.top-betbtn .btn-bg-d span {
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.top-betbtn .btn-bg-g .nut-button--danger{ color: #fff;}

</style>
