// 西班牙语
module.exports = {
  // 枚举
  filtersText:{
    wait_audit: 'Wait Audit',
    audit_accept: 'Audit Accept',
    audit_reject: 'Audit Reject',
    processing: 'Processing',
    success: 'Success',
    failed: 'Failed',
  },
  // 通用
  global:{
    language: 'Language',
    recharge: 'Recharge', //底部主导航
    invite: 'Invitar',     //底部主导航
    reward: 'Misiones',     //底部主导航
    my: 'Mi',             //底部主导航
    myWin: 'Mi Gana',
    loading: "Loading...",                                    //加载中提示 1
    okText: "OK",                                             //ok按钮文字 1
    cancelText: "Cancelar",                                     //取消按钮文字 1 
    safeDays1: 'Lleva funcionando de forma segura durante',               //安全运营天数： safeDays1 +  ??? + safeDays2
    safeDays2: 'safeDays',
    dataListInfo :'Sólo muestra datos de los últimos 3 meses!',          //只展示3个月数据  2  Only show the Last 3 Month Data
    noMore:'No hay más!',                                        //没有更多数据了
    noData:'No hay datos!',                                        //没有更多数据了
    inputRequired:'Rellene los campos obligatorios!',      //请填写必填项
    errInfoRequired:'Esto es necesario',                       //必填项错误提示
    mobileError: 'Formato incorrecto del número de móvil!',               //电话号码错误提示
    passwordsError: 'Contraseñas diferentes dos veces!',            //两次密码不一致
    agreeError: 'Por favor, acepte el acuerdo de registro', //请同意注册协议
    successfully_OPT: 'Enviado correctamente',
    successfully: 'Enviado correctamente',                      //短信验证码发送成功
    errorfully: 'Número de móvil incorrecto!',                       //短信验证码发送失败
    networkError:'Anomalía en la red!',                          //网络错误
    eMail:'Correo electrónico de respuesta: ',                //提现底部提示1
    WithdrawalPartners:'Socios de pago/retiro abajo.',  //提现底部提示2
    submittedSuccess:'Enviado con éxito!',               //提交成功
    confirm:'Confirmar',                                        //确定按钮
    submit:'Enviar',                                          //提交按钮
    save:'Guardar',                                              //保存按钮
    view:'ver',                                              //查看按钮
    downloadApp: 'Descargar aplicación',                              //下载app
    addDesktop: 'Añadir al escritorio',                             //添加到桌面
    addDesktopTitle: 'Para su comodidad, se recomienda añadir nuestra página al escritorio',  //为了你更方便的使用，建议把我们添加到桌面
    addDesktopText1a: '1、Haga clic en el icono',               //1、点击 xx 图标   (第一步)
    addDesktopText1b: 'icono',                  //1、点击 xx 图标
    addDesktopText2: '2、Seleccione "Añadir al escritorio" ',   //  (第二步)选择 "添加到主屏幕"
    rechargeBtnText:'Recargar',  //充值按钮
    withdrawalBtnText:'Retirada',  //提现按钮
    inviteBtnText:'invitar',  //邀请按钮
    slogan1: '100% seguro y real, ¡sin trampas!',
    Today: 'Hoy', //आज
    Week: '1 Semana', //1 सप्ताह
    Month1: '1 Mes', //1 महीना
    Month3: '3 Mes', //3 महीना
    Your_invitation_link_has_been_copied: 'Su enlace de invitación ha sido copiado!',
    hotGame: 'Juego caliente',
    newGame: 'Juego novo',
    top10: 'Top 10 de jugadores',
    NON_VIP_CAN_ONLY_INPUT: 'Los no vip sólo pueden apostar',
    INVALID_INPUT_VALUE: 'Valor de apuesta no válido.',
    REQUEST_FAILED_PLEASE_TRY_LATER_OR_CONTACT_US: 'Solicitud fallida. Inténtelo más tarde o póngase en contacto con nosotros.',
    SERVER_ERROR: 'Error del servidor. Inténtelo más tarde o póngase en contacto con nosotros.',
    SIGN_UP_BONUS_1: 'Sign Up To Get ', //Sign Up To Get ** Bonus >>
    SIGN_UP_BONUS_2: ' Bonus >>',
    SIGN_UP_BONUS_3: 'Register to get a discount coupon ',
    ALREADY_HAVE_AN_ACCOUNT: 'Already have an account?',
    
    RECEBER_CUPOM_DE_DESCONTO_DE: 'Receive discount coupon ',
    OBTER_CUPOM: 'Get coupon',
    DISCOUNT_COUPON: 'Discount coupon',
    VALID: 'Valid',
    COUPON: 'Coupon',
    EXPIRED: 'Expired',
    USAR: 'Use',
    FUN_MODE: 'Fun Mode',
    RECIBO: 'Receipt',
    PAY: 'Pay',
    FUN_MODE_i: 'Fun mode amount is virtual amount, choose real mode to start real game experience',

    VIP_BONUS_1: 'Esta bonificación sólo está disponible para usuarios VIP',
    VIP_BONUS_2: '+, actualice su nivel VIP para recibirla!',
    DEMO_END_TEXT: 'Su oportunidad de experimentar hoy ha sido utilizada, por favor vaya al modo real para empezar a ganar de verdad.',
    TO_REAL_MODE: 'To real mode',
    Prediction_Channel: 'Prediction Channel',
    Prediction_Channel_Text: 'The prediction channel is only open to VIP1+ users, please upgrade your VIP level.',
    Got_It: 'Got It',
    Upgrade: 'Upgrade',

    Recharge_Dialog_Title: 'equilibrio insuficiente',
    Recharge_Dialog_Text: 'Puedes recargar o entrar en el modo diversión para experimentar el juego',
    Recharge_Dialog_Btn: 'jugar',

    Received:'Received',
    Total:'Total',
    Lucky_Lifafa:'Lucky Lifafa',
    Easy_share_Earn_Rupees:'Easy share, Earn Rupees',
    Remaining_today: 'Remaining today',
    pieces:'pieces',
    Play_now:'Play now',

    DOWNLOAD_APP: 'Download APP',
    DOWNLOAD_APP_TEXT:'For a better game experience, get rewards faster and get valuable information',
    DOWNLOAD_APP_TEXT2: 'Download the app now to claim ',
    DOWNLOAD_APP_TEXT3: 'bonus!',
    DOWNLOAD_APP_TEXT4: 'Please go to APP to receive reward!',
    DOWNLOAD_APP_BTN: 'Download',
    DOWNLOAD_APP_CANCEL: 'Cancel',
    Through_Email1: 'If there is any problem, please contact us through Email:support',
    Through_Email2: 'crash100.xyz, working hours: 7:00-20:00',
  },
  // 飞机游戏页
  game:{
    h1: 'Crash',
    tips: 'Tips',
    gamePaused: 'Juego pausado', 
    paused: 'En pausa',
    playTime: 'Tiempo de juego', 
    gameWillStartingIn: 'El juego empezará en',
    youWin: 'Usted gana',
    receiveBonus: 'Recibir bonificación',
    nextGame: 'Próxima ronda',  
    all: 'todos',
    start: 'Iniciar',
    stop: 'Parada',
    autoStop: 'Parada automática',  
    myBet: 'Mi Orden',
    players: 'Jugadores',
    myHistory: 'My Order',
    winRatio: 'Stop',   
    winAmount: 'Gana',
    betAmount: 'Puntos',
    last20: 'Mostrar sólo los últimos 20 jugadores que se unieron!',
    stopPlayer: 'Detener reproductor',
    bet: 'apuesta',
    status: 'estado',
    ratio: 'Parar',
    feeAmount: 'Tiempo',
    escape: 'Escapar',
    more: 'Sólo se muestran los pedidos de hoy, haga clic para ver más',
    noHistory: 'No hay historial de apuestas',
    historData:'Historia datos del juego',
    notEnough: 'la cantidad de su monedero no es suficiente',
    pleaseWithdraw: 'Por la seguridad de su dinero, por favor retire.',
    //pleaseWithdraw2:'¡Enhorabuena por ganar! Para la seguridad de su dinero, por favor retire.',
    pleaseWithdraw1:'¡Enhorabuena!',
    pleaseWithdraw2:'Has ganado más de  ',
    // pleaseWithdraw_200:'200',
    pleaseWithdraw3:'antes de seguir jugando,',
    pleaseWithdraw4:'por favor retirarse para garantizar ',
    pleaseWithdraw5:'puede recibir dinero con éxito.',
    go: 'GO!',
    gotitBtn: 'Entendido',
    info1: 'Invitar a un amigo',  // You can invite to fill up to xxxx
    info2: 'Recupera la bonificación inicial a ',  // You can invite to fill up to xxxx
    waitForTheNextRound: 'Esperar a la siguiente ronda!',
    info3: 'La apuesta Vip0 sólo puede ser de 10 ',
    DialogTitle: 'Enhorabuena por la gran victoria!',
    DialogContent1: 'Para vip',
    DialogContent2: ', ratio máximo de parada es ',
    DialogContent3: 'x, puedes subir de nivel vip para desbloquear el límite.',
  },
  // 红绿游戏页
  PARITY:{
    H1: 'PARITY',
    NAME1: 'Pepe',    
    NAME2: 'José',
    NAME3: 'Joselito',
    PLEASE_WAIT: 'Por favor, espere',
    ROUND: 'Ronda',
    NEXT_ROUND_PREDICTION_STSRT_AT: 'La predicción de la próxima ronda comienza en',
    THIS_PREDICTION_STARTS_AT: 'Esta predicción comienza en',
    LIVE_PREDICTION: 'Predicción en directo',
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_1: '¡Predicción en directo ya está disponible en la ',  //1 LIVE Prediction is now available in Prediction room!
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_2: 'sala de ', // 2
    LIVE_PREDICTION_IS_NOW_AVAILABLE_IN_PREDICTION_ROOM_3: 'Predicción!',       // 3
    MY_CHOICE: 'Mi Elección',
    COUNT_DOWN: 'Cuenta atrás',
    SELECT_TIME: 'Elige la hora',
    RESULT_TIME2: 'Elige la hora',
    PAUSED_TIME: 'En pausa',
    MY_POINT: 'Mi apuesta',
    VIP: 'Vip',
    JOIN_GREEN: 'Únete Verde',
    JOIN_VIOLET: 'Únete Violeta',
    JOIN_RED: 'Únete Rojo',
    JOIN_NUMBER: 'Únete Número',
    RECORD: 'récord',
    CONTINUOUS: 'Continuo',
    PROBABILITY: 'Probabilidad',
    NEXT_PAGE: 'Página siguiente',
    UPGRADE: 'Actualizar',
    EVERYONE_ORDER: "La orden de todas",
    MY_ORDER: 'Mi pedido',
    BALANCE: 'Saldo',
    FEE: 'tarifa',
    JOIN: 'únase a',
    CONTRACT_POINT: 'Importe del contrato',
    ALL: 'Todos',
    NUMBER: 'Número',
    VIP1_INPUT_FREELY: 'Vip1+ apostar libremente',
    VIP0_ROOKIE_GUIDE: 'Vip0 Guía para novatos',
    FOLLOW_ADVICE_AND_YOU_WIN_FINALLY: "¡Sigue los consejos y ganarás por fin!",
    INPUT: 'apuesta',
    LOSE: 'fallo',
    WIN: 'gana',
    SO_THIS_ROUND_SHOULD_INPUT: 'Así que esta ronda debe apostar',
    CONFIRM: 'Confirmar',
    TOTAL_PROFIT: 'Beneficio total',
    PROFIT: 'Beneficio',
    USER: 'Usuario',
    SELECT: 'Seleccione',
    POINT: 'apuesta',
    PERIOD: 'Periodo',
    RESULT: 'Resultado',
    AMOUNT: 'Ganar',
    RESULT_TIME: 'Tiempo',
    BTC_PRICE: 'Precio BTC',
    SERIAL_NUM: 'Número de serie',
    NEW: 'NUEVO',
    SUGGEST: 'Sugerir',
    PREDICT: 'Predecir',
    SERIAL: 'Serie',
    PROFIT_WIN_INPUT :'Beneficio (Win-Input)',
    RESULTS_ORIGIN: 'Resultados Origen',
    PREDICTION_GAMEPLAY: 'Juego de Predicción',
    INVESTMENT_SKILLS: 'Habilidades de inversión',
    ANALYST_INTRODUCTION: 'Introducción del analista',
    RESULTS_ORIGIN_TEXT_1: 'El resultado proviene del último lugar del índice compuesto de moneda digital coinbase Top 10.',
    PREDICTION_GAMEPLAY_TEXT_1: 'La plataforma proporciona resultados de predicciones calculados por analistas cooperativos 7 veces al día, de 7 a 15 rondas por predicción, como referencia.',
    PREDICTION_GAMEPLAY_TEXT_2: 'Tiempo de apertura de las predicciones:',
    PREDICTION_GAMEPLAY_TEXT_3: 'Una estrategia de inversión eficiente combinada con las predicciones de los analistas puede aumentar las probabilidades de ganar a largo plazo.',
    PREDICTION_GAMEPLAY_TEXT_4: '(Los resultados de las predicciones son sólo de referencia, no se garantiza su exactitud)',
    INVESTMENT_SKILLS_TEXT_1: 'Tanto la tasa de ganancia como la estrategia de inversión son importantes.',
    INVESTMENT_SKILLS_TEXT_2: '1.Estrategia de inversión',
    INVESTMENT_SKILLS_TEXT_3: 'Simple estrategia de inversión【doble apuesta】:Si pierde esta ronda, para la siguiente ronda, duplique la cantidad que invierte hasta que gane.',
    INVESTMENT_SKILLS_TEXT_4: 'Por ejemplo (3 veces apuesta doble)',
    INVESTMENT_SKILLS_TEXT_5: '2.Seguir y Opuesto',
    INVESTMENT_SKILLS_TEXT_6: 'Aprenda a observar "continuo", cuando el mismo resultado aparece en una fila, elija el resultado opuesto, y la tasa de ganar es a menudo mayor.',
    INVESTMENT_SKILLS_TEXT_7: 'Más consejos en el canal oficial de telegram>>',
    INITIAL_BALANCE: 'Saldo Inicial',
    INVESTMENT_PLAN: 'Plan de inversión',
    TOTAL_ASSETS: 'Activos total',
    ANALYST_INTRODUCTION_1: 'Licenciado en Economía Financiera por la Universidad Nacional Autónoma de México, especializado en análisis de datos financieros, con amplia experiencia en inversiones y una rentabilidad del 340.',
    ANALYST_INTRODUCTION_2: 'Raduado por el Instituto Tecnológico de Massachusetts, con un talento matemático excepcional, centrado en la práctica de la teoría matemática métodos de probabilidad de negociación, trabajando en el campo del análisis de datos en Wall Street durante más de 8 años.',
    ANALYST_INTRODUCTION_3: 'ás de 5 años de experiencia en inversiones en blockchain, MSc en Matemáticas Financieras por la Universidad de Michigan, trabajó en Mt.Gox.',
    CONGRSTS_XXX_WIN_XXX_1: 'Felicidades ',   // Congrats 900***0000 Win 8888
    CONGRSTS_XXX_WIN_XXX_2: ' ganar ',
    GAME_WILL_COMING_SOON: 'El juego llegará pronto!',
    ROOM_NOT_EXISTS: '¡Habitación no existe!',
    PLEASE_CONFIRM_YOUR_INPUT: 'Por favor, confirme su apuesta!',
    AS_A_ROOKIE_YOU_CAN_ONLY_INPUT: 'Como novato, sólo puede apostar ',
    ONLY_FOR_VIP1: 'Sólo para VIP1+',
    ONLY_FOR_VIP2: 'Sólo para VIP2+',
    CONNECTING: 'conectando',
    OOPS_PLEASE_CHECK_YOUR_NETWORK: '¡Ups! Por favor, compruebe su red',
    TRY_AGAIN:'Vuelva a intentarlo',
    BTC_PRICE_BY_BINANCE: 'Precio de BTC en Binance',
  },
  //转盘
  WHEEL:{
    H1: 'WHEEL',
    HISTORY: 'History',
    BET_TIME: 'Choose time',
    PLAYING:'Playing',
    PLEASE_WAIT: 'Please wait',
    CLICK_TO_BET: 'Click to choose',
    VIP_ONLY: 'Apenas VIP1+',
    RULE_H1: 'Wheel Rule',
    RULE_T1: '1.Cómo jugar ',
    RULE_T2: 'Los jugadores pueden seleccionar el color de la plataforma giratoria,Seleccione primero el importe de la apuesta y luego el color.',
    RULE_T3: '2.Gane',
    RULE_T4: 'Las probabilidades de azul y amarillo son 2X, apuesta 100, gana 200.',
    RULE_T5: 'Las probabilidades del rojo son 9X, apuesta 100, gana 900.',
  },
  // 挖雷游戏页
  MINE:{
    H1: 'MINE',
    ROW:'fila',
    START: 'Inicio',
    BONUS: 'Bonificación',
    NEXT: 'Siguiente',
    STOP: 'Stop',
    POINT: 'Punto',
    SELECT: 'Seleccione',
    WIN_AMOUNT: 'Cantidad ganada',
    ORDER_NUM: 'Número de orden',
    STOP_AND_GET_BONUS: 'Parar y obtener bonificación!',
    CHOOSE_GAME_AMOUNT: 'Elija el importe del juego',
  },
  // 登录页
  userLogin: {
    signIn: 'Iniciar sesión',                       //登录标题
    smsSignIn: 'Inicio de sesión SMS',                  //SMS登录标题
    mobileNumber: 'Número de móvil',           //手机输入框标题
    mobileNumberPlaceholder:'Número de móvil', //手机输入框默认提示
    password: 'Contraseña',                    //密码输入框标题
    passwordPlaceholder: '6~32 longitud',      //密码输入框默认提示
    login: 'Inicio de sesión',                          //确认登录 按钮
    loginSuccess: 'Inicio de sesión exitoso!',          //登录成功 文字提示
    forgotPassword: 'Contraseña olvidada?',      //忘记密码 链接
    register: 'Registrarse',                    //注册连接 链接
    SMSLogin: 'Inicio de sesión con verificación por SMS',      //短信登录 链接
    passwordLogin: 'Contraseña de acceso'          //密码登录 链接
  },
  // 设置密码页
  pesetPassword: {
    h1:'Restablecer contraseña',              //页面标题
    old:'Contraseña antigua',               //旧密码输入框    1 
    oldPlaceholder:'Contraseña antigua',    //旧密码输入框 提示   1
    new:'Contraseña nueva',               //新密码输入框   1
    newPlaceholder:'Contraseña nueva',    //旧密码输入框 提示 1
    confirmPassword: 'Contraseña nueva',
    confirmPasswordPlaceholder: 'Contraseña nueva',
    success: 'Establecer contraseña con éxito!', //设置成功提示  1
  },
  // 找回密码
  resetPassword:{
    h1:'Restablecer contraseña',
    newPassword: 'Contraseña nueva',
    newPasswordPlaceholder: '6~32 dígitos',
    confirmPassword:'Contraseña nueva',
    confirmPasswordPlaceholder:'Contraseña nueva',
    success: 'Establecer contraseña con éxito!'
  },
  // 用户注册
  userRegister:{
    h1:'Registrarse',                                  //页面大标题
    registerTopText1: 'La plataforma en línea certificada por el Gob.  Su cuenta y sus fondos están regulados por el Banco. Usted puede retirar en cualquier momento.',  //注册顶部宣传文案
    registerTopText2: 'Nueva bonificación de registro',    //注册顶部宣传文案 第2段： New register bonus xxx
    registerText3: 'Correo electrónico comercial:',
    registerText4: 'Email de servicio:',
    verificationCode: 'Código de verificación',          //验证码输入框标题
    verificationCodePlaceholder: '6 dígitos',        //验证码输入框默认提示
    OTP: 'OTP',                                     //发送验证码 按钮
    confirmPassword: 'Contraseña nueva',            //确认密码输入框 标题
    confirmPasswordPlaceholder: 'Contraseña nueva', //确认密码输入框默认提示
    recCode: 'Código de recomendación',                   //邀请码输入框
    recCodePlaceholder: 'No es necesario',             //邀请码输入框 默认提示
    iAgree: 'Acepto',                              //同意注册协议 文案
    privacyPolicy: 'Política de privacidad',                //同意注册协议 链接
    registerBtn: 'Registrarse',                        //确认注册 按钮
    success:'Éxito de registro!',                //注册成功 弹窗 文案
    signUpBonus:'bonificación por registro!',    //注册成功 弹窗 文案
    signUpCoupon:'Received discount coupon',          //收到优惠券
  },
  
  //我的主页
  home: {
    h1: 'Mi casa', 
    mobile: 'Móvil',         // 改成 phone
    vip: 'VIP',               //
    inviteCode: 'Código de invitación', //
    balance:'Saldo',        //
    recharge:'Recarga',      //
    withdrawal:'Retirada',  //
    vipDescription: 'Descripción VIP',
    link:'Canal oficial',      // Official Telegram
    link_r: 'Bonificación diaria',
    promotion: 'Promoción',            //
    envelopesCenter:'Centro de sobres',//
    transactions:'Transacciones',       //
    bankCard:'Tarjeta bancaria',            //  ph: GCash
    resetPassword:'Restablecer la contraseña',    //
    cs:'Quejas y sugerencias',     //
    about:'Acerca de',                     //
    logout:'Cierre de sesión',          //退出登陆
    vipTitle: 'Privilegios VIP Descripción',   //
    withdrawakQuoat: 'Cuota de retirada',      //
    level: 'Nivel VIP',                       //
    totalRecharge: 'Recarga total',          //
    profitRatio: 'Ratio de beneficios',              //
    withdrawalServices: 'Servicio de retirada',//
    vipRewards: 'Recompensas VIP',                //
    dt: 'Descripción del monto del retiro:',  //
    dd1: '1、Retirada total = Recarga total x Ratio de beneficio;',  //
    dd2: 'El retiro VIP8 no está sujeto a restricciones de recarga, por lo que puede retirar tanto como gane.',  //
    gameAmount: 'El saldo de la cuenta del juego proviene de las ganancias y recargas de la plataforma.',
    agentAmount: 'El saldo de la cuenta del agente proviene del sistema de comisiones del agente.'
  },
  // 设置头像
  setAvatar: {
    h1:'Establecer avatar',                  //页面大标题
    current:'Avatar actual',         //当前头像
    avatars:'Avatares para elegir:',//选择头像
    success:'Avatar sustituido con éxito!' //成功提示
  },
  //银行卡
  bankCard: {
    h1: 'Tarjeta bancaria',
    noBankcCard: 'Ninguna tarjeta bancaria está vinculada actualmente!',
    addBankcCard: 'Añadir tarjeta bancaria',
    delete: '¿Borrar o no?',
    editTitle: 'Editar tarjeta bancaria',      //编辑银行卡
    addTitle: 'Añadir tarjeta bancaria',        //添加银行卡
    realName: 'Nombre real',            //输入框字段
    realNamePlaceholder: 'Nombre real', //输入框字段 提示
    ifscCode: 'Código IFSC',            //输入框字段
    ifscCodePlaceholder: 'Código IFSC', //输入框字段 提示
    bankName: 'Nombre del banco',            //输入框字段
    bankNamePlaceholder: 'Nombre del banco', //输入框字段 提示
    bankAccount: 'Cuenta bancaria',      //输入框字段
    clabeNumber: 'CLABE Números',      //输入框字段
    bankAccountPlaceholder: 'Cuenta bancaria',//输入框字段 提示
    upiVpa: 'UPI ID (Vpa)',                //输入框字段
    upiVpaPlaceholder: 'UPI ID (Vpa)',     //输入框字段 提示
    email: 'E-mail',                   //输入框字段
    emailPlaceholder: 'Email',        //输入框字段 提示
    success:'Éxito',                //成功提示
    addcard_text:'Por favor, asegúrese de que la información es correcta, evitar no ser capaz de recibir los retiros.',
    GCash: 'GCash', 
    GCashPlaceholder: 'GCash Mobile', 
  },
  //投诉 & 建议
   CS: {
    h1: 'Quejas Sugerencias',
    noData: 'No hay quejas ni sugerencias!',
    addBtn: 'Añadir quejas',
    waitReply: 'Esperar Respuesta',
    replied: 'Respondido',
    h2: 'Escribir quejas o sugerencias',
    type: 'Tipo',
    typePlaceholder: 'Seleccione el tipo',
    title: 'Título',
    titlePlaceholder:'Por favor, introduzca el título!',
    description: 'Descripción',
    descriptionPlaceholder: 'Introduzca la descripción',
    servicTime: 'Servicio:10:00~16:00, Mon~Fri',
    servicTime2: 'Por favor, proporcione capturas de pantalla de su problema y su id, enviar a nuestro correo electrónico de servicio al cliente: support@oxxowin.com',
    recharge: 'Recarga',
    withdraw: 'Retirar',
    invite: 'Invitar',
    gameCrash: 'Crash del juego',
    others: 'Otros',
  },
  // 消息
  envelopes: {
    h1:'Centro de sobres',  //页面大标题
    new: 'nuevo',             //新消息标识
  },
  // 充值页
  recharge: {
    h1:'Recargar',               //页面大标题
    balance: 'Saldo',          //标题
    amount: 'Importe',            //金额title
    amountPlaceholder: 'Importe', //金额输入框提示
    receipt: 'Recibo',          //手续费title
    receiptPlaceholder: 'Importe',//手续费输入框提示
    channel: 'Canal',          //渠道title
    success: 'Recargar con éxito!',//充值成功
    failSelect: 'Seleccione un canal de recarga!',         //请选择充值渠道！
    failEnter: 'Introduzca el importe correcto!',           //请输入正确的金额
    pop: 'Complete el pago en la nueva página, por favor espere.',             //请在新页面完成支付
    description: 'Descripción',
    tx5: '1. Si hay algún problema con la recarga, póngase en contacto con nosotros a través de ',
    tx5_1: 'soporte Telegram',
    tx5_2: ' ,horas de trabajo: 7:00-20:00',
    tx6: '2. El importe de la recarga aumentará su nivel VIP, y un nivel VIP más alto aumentará su importe de retirada, bonificación diaria, etc.',
    tx7: 'Consulte la',
    tx8: '“Descripción VIP”',
    tx9: '3. No revele su contraseña para evitar que se la roben. Nuestro personal no le pedirá su contraseña..',
    levelUpVip: 'Actualizar ahora!',
    popTitle_1:'Descripción VIP',
    popTitle_2:'Descripción VIP',
  },
  // 充值列表页
  rechargeList: {
    h1:'Historial de recargas',         //页面大标题
    giftAmount: 'Historial de recargas',     //列表字段
    applyAmount: 'Aplicar importe',   //列表字段
  },
  // 提现页
  withdrawal: {
    h1:'Retirada',                       //页面大标题
    availableBalance:'Saldo disponible',  //可用余额
    agentAmount: 'Cuenta del agente',         //邀请金额
    gameAccount: 'Cuenta de juego',           //游戏金额
    transfer: 'Conversión',
    withdrawAmount: 'Importe retirada',     //提现金额 输入框标题
    handlingFee: 'Gastos de gestión',           //手续费 输入框标题
    receiptAmount: 'Importe del recibo',       //收款金额 输入框标题
    bankCard: 'Tarjeta bancaria',                 //银行卡选择 标题
    withdrawalBtn: 'Retirada',           //提交 按钮
    errInfoBankCard: 'Por favor, añada una tarjeta bancaria para retirar.', //请先添加银行卡 提示信息
    errInfoRecharge: 'Necesita recargar más para retirar.', //请先充值才能提现 提示信息
    errInfopPlayMore: 'Señor/Señora, ha apostado muy poco, por favor siga jugando.',    //请先玩更多才能提现 提示信息
    description:'Descripción',
    tx4: '1.Si hay algún problema con el recharge, por favor contáctenos a través de ',
    tx4b: 'Si hay algún problema con el recharge, por favor contáctenos a través de ',
    tx5: '1.Si hay algún problema con el retiro, por favor contáctenos a través de ',
    tx5b: 'Si hay algún problema con el retiro, por favor contáctenos a través de ',
    tx_contact_us:'contact us',
    tx5_1: 'soporte Telegram',
    tx5_2: ' , working hours: 7:00-20:00',
    tx6: '2.Un nivel VIP más alto puede aumentar el límite de retirada.',
    tx7: 'Consulte la',
    tx8: '“Descripción VIP”',
    tx9: '3.Para evitar el blanqueo de dinero, debe alcanzar un importe de apuesta suficiente antes de poder retirar.',
    tx10: 'Enhorabuena!',
    tx11: '🎉🎉🎉La seguridad de tu cuenta ha sido verificada🤝🤝🤝Ahora puedes seguir jugando!🥳🥳🥳',
    btntx: 'Jugar',
    popBankList: 'Esta tarjeta bancaria no es compatible por el momento, la siguiente es una lista de los bancos disponibles!'
  },

  // 提现列表页
  withdrawalList: {
    h1:'Historial de retiradas',            //页面大标题
    receiptAmount: 'Importe recibido',    //列表字段
    applyAmount: 'Aplicar importe',        //列表字段
    withdrawFee: 'Tarifa de retirada',        //列表字段
    pleaseCheckBank: 'Por favor, confirme que su información bancaria es correcta y retire de nuevo',    //列表字段
  },

  // 金币转换
  transfer: {
    h1:'Transferencia',
    transferAmount: 'Importe de la transferencia',
    btntx: "Confirmar",
    success: 'Transferencia realizada!',
    tx1: 'El importe del juego procede de los beneficios y recargas de la plataforma y el importe del agente procede del sistema de comisiones del agente.',
    tx2: 'Si lo necesita, la cantidad del agente puede ser transferida libremente a la cantidad del juego para jugar, la cantidad del juego no puede ser transferida a la cantidad del agente, ambas cantidades pueden ser retiradas.',
  },
  //钱包明细列表
  transactions:{
    h1:'Transacciones',                   //页面大标题
    cashH1:'Transacciones en efectivo',
  },
  //任务页
  taskReward:{
    h1: 'Misiones',                   //页面大标题
    day: 'Día',                          //新手任务   xx day
    checkIn:'Registrarse',
    checkInLocked:'Bloqueado',
    toTask: 'Ir a',                //去做任务按钮
    dailyQuest: 'Búsqueda diaria',
    dailyQuestLocked: 'Búsqueda diaria (¡Completada!)',
    dailyQuestCompleted: 'Búsqueda diaria (¡Completada!)',
    dailyQuestFinished: 'Búsqueda diaria (Finalizada)',
    completeTask: 'Ir a',//完成任务
    receiveReward: 'Recibir recompensa',       //领取奖励按钮
    received: 'Recibido',                //已经领取按钮
    completed: 'Completar',              //按钮
    complete: 'Completar',                //完成百分比    100% Complete
    checkIn7Text: 'Iniciar sesión durante 7 días consecutivos',      //签到进度标题  Check in for 7 consecutive days (x/x)
    vipCheckInText: 'Suba de categoría VIP para obtener más bonificaciones',//签到进度副标题
    checkInText: 'Inicie sesión diariamente para recibir recompensas',    //7天之后的 签到标题
    missionTarget: 'Misiones',     //任务列表标题
    getBn: 'Recompensa recibida!',              //领取弹框 大标题
  },
  // 代理激励计划
  agentGrowth: {
    h1: 'Crecimiento del agente',
    bn1: "El plan de crecimiento en efectivo del Agente crore se divide en ",   //bn1 + xx + bn2 + xx + bn3!
    bn2: " niveles, y cada nivel tiene generosas recompensas. Completa el nivel más alto y obtén una recompensa de ",
    bn3: " inmediatamente.",
    target:'objetivo',
    h4_1: 'Condiciones de la recompensa',
    h4_item1_1: 'Invita a',
    h4_item1_2: 'persona efectiva(registro y recarga), puedes conseguir dinero en efectivo.',
    h4_2: 'Mejora tus habilidades',
    h4_item2_1: 'Si compartes el enlace y otra persona se registra a través del enlace, esa persona será tu subordinado. Una vez que esa persona tenga una recarga(se haga efectiva), podrás obtener y retirar ',     
    h4_item2_2: 'inmediatamente.',
  },
  // 代理激励计划
  AGENT_SUPPORT_PLAN: {
    H1: 'Agent Support Plan',
    MY_SUPPORT_PLAN: 'My Support Plan',
    MY_SUPPORT_PLAN_LEVEL: 'My Level',
    TODAY_EFFECIVE_USERS_AMOUNT: 'Today Effecive Users Count',
    TODAY_POINTS_BONUS: 'Today Input Bonus',
    YESTERDAY_SUPPORT_REWARD: 'Yesterday Support Reward',
    BTN_TEXT: 'Click to receive reward >>',
    INFO_TEXT: 'If the system detects any cheating, bonuses and accounts will be frozen.',
    AGENT_REWAED_TOP: 'Agent Reward top 5',
    LEVEL: 'Level',
    LV: 'Lv',
    PHONE: 'Phone',
    ORIGINAL_SYSTEM_REWAED: 'Original System Reward',
    SUPPORT_REWARD: 'Support Reward',
    ONLY_TOP_5: 'Only show Top 5 data!',
    DAILY_REQUIRE: 'Daily Require',
    REWARD: 'Reward',
    EFFECIVE_USERS_AMOUNT: 'Effecive Users Count',
    POINTS_BONUS: 'Input Bonus',
    TOTAL_SUPPORT_REWARD: 'Total Support Reward',
    CONSECUTIVE_DAYS_EXTRA_REWAED: '7 Consecutive Days Extra Reward',
    I_E_Q: '(Invite + Effective + Quests)Bonus'
  },	
  // 邀请
  invite: {
    h1: 'Invite a',
    agentAmount: "Agente Importe",
    withdraw:'Retirar',
    totalPeople: 'Total de personas',
    effectiveNum: 'Número efectivo',
    h3: 'Crore Plan de crecimiento de agentes',
    contribution: 'bono de contribución',
    more: 'deslizar para ver más',                                           //更多提示
    affinity: 'Grado',                                                //table 字段
    inviteCount: 'Recuento de invitaciones',                                         //table 字段
    effectiveCount: 'Recuento efectivo',                                   //table 字段
    effectiveBonus: 'Bonificación efectiva',                                   //table 字段
    inviteCommissionAmount: 'Bonificación por invitación',                  //table 字段
    subBetCommissionRatio:'Bonificación por puntos',                    //table 字段
    subBetCommissionAmount:'Bonificación por puntos',                  //table 字段
    subFirstRechargeCommissionRatio:'Sub Primera Recarga CommissionRatio',//table 字段
    subFirstRechargeCommissionAmount:'Sub Primera Recarga Importe Comisión',//table 字段
    totalCommissionAmount:'Prima total',                     //table 字段
    Grade: 'Grado',
    Bonuss: 'Bonificación',
    rules: 'Reglas',                 //tab标题1
    bonus: 'Bonificación',                 //tab标题2
    myCode: 'Mi código promocional',    //tab 右侧链接标题
    you: 'Usted',
    youTitle: 'Sistema de agencia de 4 niveles, cada nivel aporta comisión!',
    level: 'Grado',              // 等级title
    label1: 'Bonificación',     // 第1个字段名称

    rulesTitle1: 'El más fácil',         // 规则1标题
    rulesText11: 'Recompensa 1~99 por cada usuario registrado,obtén ingresos inmediatamente!', // 规则1内容
    rulesText12: 'Si invitas a 100 usuarios, gana al menos 400 y posiblemente hasta 9900',

    rulesTitle2: 'El más largo',         // 规则2标题
    rulesText21: 'Sistemas de cuatro niveles,cada usuario generará comisión!', // 规则2内容
    rulesText22: 'Si cada persona invita a 10 personas, cada persona sólo tiene que apostar 100 por día, después de 30 días, a través del sistema de agentes de 4 niveles, ganará más de 35.000', // 规则2内容

    rulesTitle3: 'Lo más efectivo',         // 规则3标题
    rulesText31: 'Haz clic y comparte el enlace del bono en el canal oficial de telegram, gana dinero al alcance de tu mano！', // 规则3内容
    rulesText32: 'Nuestro agente Hernández es un vendedor ambulante que invita a 100 nuevos usuarios a registrarse cada día compartiendo el enlace, probablemente gane unos 1500 ¤ al día. Si él puede, tú también!',

    rulesTitle4: 'Qué es Recall?',
    rulesText41: 'Si un usuario no ha visitado OXXOWIN en 21 días, y vuelve a visitarlo a través de tu enlace de invitación o enlace lifafa forward, será considerado como tu nuevo usuario invitado. Sigue promocionando OXXOWIN y seguirás consiguiendo nuevos usuarios y comisiones.',
    
    h4: 'Mi código de promoción',
    h4Title: 'el más caliente en línea que hace la plataforma de dinero en 2023.earnily 30,000~200,000',
    btn: 'Copiar enlace',
    to: 'o compartir a',
    shareMakeMoneya: 'Compartir ganar dinero',
    info1: 'Cada usuario que invites te reportará una bonificación de MAX 99,y aquellos a los que invites obtendrán ',
    info2: ' recompensas.',
    //ShareNetwork
    url: 'https://oxxowin.com',        //分享链接
    title: 'oxxowin La plataforma de ganancias más segura (https://oxxowin.com/)',      //分享标题
    description: 'Formas rápidas y sencillas de ganar dinero en la México en 2023 Bonificación inicial🚀Retirada en 3m🚀100X Win🚀₹ Recompensa de 10 millones de acciones🚀100% Seguro🚀 Cuanto antes te unas, ¡más ganarás!',//分享描述
    quote: 'Cuanto antes te unas, más ganas!',  
    hashtags: 'game,crash',  //分享关键词
    twitterUser: 'oxxowin',     //作者
    cashHistory:'Historial de caja',
    infoToast: 'Los usuarios invitados deben cumplir la norma de número efectivo exigida por el sistema, de lo contrario no se contabilizarán. Invite a más usuarios efectivos y se reanudarán las estadísticas..',

    Congratulations:'Congratulations',
    NewAgencyIncome:'Recent Agency Income',
    NewInvites:'Recent Invites',
    NewEffective:'Recent Effective',
    EarnMoreNow:'Earn More Now',
    Last30Days: 'The data comes from the last 30 days',
  },
  //我的历史下注
  myPlayList: {
    h1: 'Mi pedido',      //页面大标题
    bet: 'Apuesta: ',
    win: 'Ganar: ',
    crash: 'Caída',
    ratio: 'Parar',
    originWinAmount: 'Ganar: ',
    feeAmount: 'Tarifa: ',
    gameNum: '',
    time:'Tiempo'
  },
  //领取红包页面
  receiveBonus:{
    h1: 'Recibir bonificación',              //页面大标题
    name: 'Su amigo',              // xxxx  （没有手机号的时候会显示这个字段）
    h2: 'le envía una bonificación!',        // xxxx 给你发来了红包！
    h3: 'ha enviado',
    btn: 'Recibir',               //按钮
    btn2: 'Recibir la última bonificación', //按钮
    success: 'Recibir éxito!',      //成功提示
    user: 'Usuario',                     // 用户 xxxx 领取了红包  前
    receives: 'recibe la recompensa!',// 用户 xxxx 领取了红包  后
    // errText: 'Por favor, haga clic en el botón para obtener el último bono de acciones!',
    errText: '',
  },
  //分享红包页面
  shareBonus:{
    h1: 'Compartir bonificación',                         //页面大标题
    h2: 'Reciba la bonificación con éxito!', //页面标题
    h3: 'Compartir con otros le reportará un 100% de beneficios!',   //副标题
    btn: 'Compartir para ganar dinero',                 //按钮
    copied: 'Su enlace de bonificación copiado!',//复制地址之后提示文字
  },
  //小提示
  tips:{
    tips1: 'Puntos mínimos 10,Puntos máximos 10000',
    tips2: 'Consigue dinero completando tareas cada día!',
    tips3: 'Los cohetes pueden volar hasta X100!',
    tips4: 'Encontrar patrones es la clave para ganar!',
    tips5: 'Francisco es el primer paracaidista en ganar con éxito 85,77 veces, ríndele homenaje!',
    tips6: 'Choque continuo a baja altura, la posibilidad de volar mucho más alto en la siguiente ronda aumentará!',
  },
    //vip说明
  VIP_PRIVILEGE: {
    VIP_PRIVILEGE: 'Privilegio VIP',
    WITHDRAWAL_DESCRIPTION: 'Descripción de la retirada',
    LEVEL: 'Nivel',
    TOTAL_RECHARGE: 'Recarga total',
    WITHDRAWAL_QUOTA: 'Cuota de retirada',
    REWARDS: 'Recompensas',
    DAILY_BONUS: 'Bonificación diaria',
    EXTRA: 'Extra',
    WITHDRAWAL: 'Retirada',
    STOP_LIMIT: 'Límite de parada',
    INPUT_LIMIT: 'Límite de apuesta',
    DATA_ANALYSIS: 'Análisis de datos',
    ROOM_LIMIT:'Límite de habitaciones',
    MAX: 'Max',
    RECORD: 'Registro',
    CONTINUOUS: 'Continuo',
    PROBABILITY: 'Probabilidad',
    TEXT1: 'Cantidad retirable(Lv1-Lv3)=Saldo - Bonificación inicial(',
    TEXT2: ') Suba a VIP4 para retirar libremente ¡el límite de retirada hasta 12000!',
    TEXT3: 'Suba al nivel VIP correspondiente para completar esta retirada',
  },
  //游戏规则 飞机
  gameRules:{
    h1: 'Reglas del juego',
    dt1: 'Cómo jugar?',
    dd1_1: 'En primer lugar, elija la cantidad que desea pedir. Haga clic en "INICIAR" para realizar su pedido y observe cómo aumenta el multiplicador de 1,00x en adelante! ',
    dd1_2: 'Puede hacer clic en "STOP" en cualquier momento para multiplicar su apuesta con el multiplicador actual. Pero tenga cuidado: El juego puede terminar en cualquier momento, y si lo detienes para entonces, ¡no ganarás nada! ',
    dd1_3: 'Tienes que esperar a que empiece la siguiente ronda.',
    dt2: '¿Cómo se calcula el importe?',
    dd2_1: 'Si el importe de su orden es 100, el múltiplo de stop es 10,0x, después de deducir 3 de comisión de servicio, el importe de su contrato es de 97:',
    dd2_2: 'Obtendrá (97 * 10) 970.',
    dt3: '¿Hasta dónde puede llegar el multiplicador?',
    dd3: 'Hasta 100 veces',
    dt4: 'Qué es la parada automática?',
    dd4: 'Si elige la parada automática, cuando el múltiplo alcance el múltiplo seleccionado, se detendrá automáticamente y obtendrá recompensas.',
    dt5: '¿Por qué los multiplicadores son de diferentes colores? ',
    btn: 'Ya lo tengo',
  },
  //游戏规则 
  gameRulesParity:{
    h1: 'Reglas del juego',
    dt1: 'Rápido',
    dd1_1: 'Una ronda de 30 segundos, 25 segundos para hacer un pedido, 5 segundos para anunciar el resultado, 24 horas sin parar,un total de 2880 rondas al día.',
    dd1_2: 'Introduzca 100 en una ronda',
    dd1_3: 'Únete Verde:',
    dd1_4: 'Si el resultado es 1,3,7,9, obtendrá 195, si el resultado es 5, obtendrá 142,5.',
    dd1_5: 'Unir rojo:',
    dd1_6: 'Si el resultado es 2,4,6,8, recibirá 195, si el resultado es 0, recibirá 142,5.',
    dd1_7: 'Únete a Violeta:',
    dd1_8: 'Si el resultado es 0,5, obtendrá 427,5.',
    dd1_9: 'Elegir número:',
    dd1_10: 'Si el resultado es el número de su elección, obtendrá 855.',

    dt2: 'Predicción',
    dd2_1: 'Una ronda de 90 segundos, 80 segundos para hacer un pedido, 10 segundos para anunciar el resultado, 24 horas sin parar, un total de 960 rondas al día.',
    dd2_2: 'Introducir 100 en una ronda',
    dd2_3: 'Únete Verde:',
    dd2_4: 'Si el resultado es 1,3,7,9, obtendrá 195, si el resultado es 5, obtendrá 142,5.',
    dd2_5: 'Unir rojo:',
    dd2_6: 'Si el resultado es 2,4,6,8, recibirá 195, si el resultado es 0, recibirá 142,5.',
    dd2_7: 'Join Violet:',
    dd2_8: 'Si el resultado es 0,5, obtendrá 427,5.',
    dd2_9: 'Chose Number:',
    dd2_10: 'Si el resultado es el número de su elección, obtendrá 855.',

    dt3: 'La verdad es la feria real, fiable debe exigir transparencia',
    dd3_1: 'Para garantizar los intereses de cada usuario inversor, con el fin de cumplir las normas de certificación IBAS y MGA, se dan las siguientes instrucciones para la generación de los resultados de los números de paridad.',
    dd3_2: '1. Fuente de resultados: Tomar el último dígito del precio de cierre del par comercial al contado Binance BTC/USDT en el momento en que finaliza el tiempo de elección de paridad.',
    dd3_3: '2. Verificación de datos: Puede ver el tiempo de los resultados históricos y el precio correspondiente de la página de la orden, y comparar los datos históricos de segundo nivel de Binance.',
    dd3_4: "Nota: Binance es actualmente la mayor bolsa de criptodivisas del mundo.BTC/USDT se refiere al precio de negociación de Bitcoin/Tether. Con un volumen de negociación de 760k / día, nadie puede manipular las cifras de precios en cuestión de segundos.",
    btn: 'Lo tengo',
  },
  gameRulesMine:{
    h1: 'Regla de la mina',
    dt1: 'Tres formas de jugar: 2x2, 4x4, 6x6, cada forma tiene diferente número de cajas y bonificaciones.',
    dt2: 'Toma 4x4 como ejemplo, un total de 16 cajas, una de las cuales está enterrada con una mina, tienes que encontrar cajas sin mina, y recibirás una bonificación cada vez que encuentres una.',
    dt3: 'Haga clic en Inicio, seleccione la cantidad de entrada, y empezar a jugar.',    
    dt4: 'Si no se evitan las minas, fin de la partida',
  },
  // 注册协议
  privacyPolicy:{
    p1:'This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use the Service and tells You about Your privacy rights and how the law protects You.',
    p2:'Interpretation and Definitions',
    p3:'Interpretation',
    p4:'The words of which the initial letter is capitalized have meanings defined under the following conditions.',
    p5:'The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.',
    p6:'Definitions',
    p7:'For the purposes of this Privacy Policy:',
    p8:'You means the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.',
    p9:'Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Coem Shop.',
    p10:'Affiliate means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.',
    p11:'Account means a unique account created for You to access our Service or parts of our Service.',
    p12:'Website refers to Coem Shop, accessible from https://www.crash100.xyz',
    p13:'Service refers to the Website.',
    p14:'Country refers to: Mexico City, Mexico',
    p15:'Service Provider means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.',
    p16:'Third-party Social Media Service refers to any website or any social network website through which a User can log in or create an account to use the Service.',
    p17:'Personal Data is any information that relates to an identified or identifiable individual.',
    p18:'Cookies are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.',
    p19:'Device means any device that can access the Service such as a computer, a cellphone or a digital tablet.',
    p20:'Usage Data refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
    p21:'Collecting and Using Your Personal Data',
    p22:'Types of Data Collected',
    p23:'Personal Data',
    p24:'While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:',
    p25:'Email address',
    p26:'First name and last name',
    p27:'Phone number',
    p28:'Address, State, Province, ZIP/Postal code, City',
    p29:'Usage Data',
    p30:'Usage Data',
    p31:'Usage Data is collected automatically when using the Service.',
    p32:"Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.",
    p33:'When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.',
    p34:'We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.',
    p35:'Tracking Technologies and Cookies',
    p36:'We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service.',
    p37:'You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service.',
    p38:'Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.',
    p39:'We use both session and persistent Cookies for the purposes set out below:',
    p40:'Necessary / Essential Cookies',
    p41:'Type: Session Cookies',
    p42:'Administered by: Us',
    p43:'Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.',
    p44:'Cookies Policy / Notice Acceptance Cookies',
    p45:'Type: Persistent Cookies',
    p46:'Administered by: Us',
    p47:'Purpose: These Cookies identify if users have accepted the use of cookies on the Website.',
    p48:'Functionality Cookies',
    p49:'Type: Persistent Cookies',
    p50:'Administered by: Us',
    p51:'Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.',
    p52:'For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy.',
    p53:'Use of Your Personal Data',
    p54:'The Company may use Personal Data for the following purposes:',
    p55:'To provide and maintain our Service, including to monitor the usage of our Service.',
    p56:'To manage Your Account: to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.',
    p57:'For the performance of a contract: the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.',
    p58:"To contact You: To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.",
    p59:'To provide You with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.',
    p60:'To manage Your requests: To attend and manage Your requests to Us.',
    p61:'We may share your personal information in the following situations:',
    p62:'With Service Providers: We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.',
    p63:'For Business transfers: We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of our business to another company.',
    p64:'With Affiliates: We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.',
    p65:'With Business partners: We may share Your information with Our business partners to offer You certain products, services or promotions.',
    p66:'With other users: when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside. If You interact with other users or register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media Service may see You name, profile, pictures and description of Your activity. Similarly, other users will be able to view descriptions of Your activity, communicate with You and view Your profile.', 
    p67:'Retention of Your Personal Data',
    p68:'The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.',
    p69:'The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.',
    p70:'Transfer of Your Personal Data',
    p71:"Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from Your jurisdiction.",
    p72:'Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.',
    p73:'The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.',
    p74:'Disclosure of Your Personal Data',
    p75:'Business Transactions',
    p76:'If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.',
    p77:'Law enforcement',
    p78:'Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
    p79:'Other legal requirements',
    p80:'The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:',
    p81:'Comply with a legal obligation',
    p82:'Protect and defend the rights or property of the Company',
    p83:'Prevent or investigate possible wrongdoing in connection with the Service',
    p84:'Protect the personal safety of Users of the Service or the public',
    p85:'Protect against legal liability',
    p86:'Security of Your Personal Data',
    p87:'The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.',
    p88:"Children's Privacy",
    p89:'Our Service does not address anyone under the age of 16. We do not knowingly collect personally identifiable information from anyone under the age of 16. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 16 without verification of parental consent, We take steps to remove that information from Our servers.',
    p90:"If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.",
    p91:'Links to Other Websites',
    p92:"Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.",
    p93:'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
    p94:'Changes to this Privacy Policy',
    p95:'We may update our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.',
    p96:'We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.',
    p97:'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page',
    p98:'Other',
    p99:"If the loss is caused by the user's wrong input of bank account information, the user shall be responsible for the loss.",
  },
}
