<template>
  <div class="myHome">
    <nut-navbar
      @on-click-icon="iconClick"
      fixed=true
      :left-show="false"
      :titIcon="icon"
      class="mb0 nav-icon-right nut-navbar--fixed nut-navbar-b0"
      title="My Home"
    >
    </nut-navbar>
    <!-- <div class="home-sl">
      <SetLanguage></SetLanguage>
    </div> -->
    <div class="my-info">


      <div class="my-info-t">
        <div class="p-avatar">
          <!-- <img class="abg" v-show="userInfo.avatar !== ''" :src="userInfo.vipBg" @click="avatarClick(userInfo.myAvatar)" /> -->
          <img class="avatarimg" v-show="userInfo.avatar !== ''" 
            :src="userInfo.avatar"
            @click="avatarClick(userInfo.myAvatar)"/>
        </div>
        <!-- <ul>
          <li class="tx" v-show="userInfo.vipLevel > 0" @click="vipPopupClick">
            <span>{{$t('home.vip')}}{{ userInfo.vipLevel }}</span>
          </li>
        </ul> -->
      </div>




    </div>


    <div>
      <div class="my-info-c">
        <div class="t">
          <div class="h5">
            <span  @click="infoToast($t('home.gameAmount'))">
              {{$t('withdrawal.gameAccount')}}
              <nut-icon name="ask" class="copyicon" style="vertical-align: bottom;"></nut-icon>
            </span>
            <strong>{{ $filters.currencySymbol(fundAccount.coinAccount) }}</strong>
          </div>
          <div class="btn-box">
            <span class="btn-t" @click="rechargeClick('cash')">{{$t('home.recharge')}}</span>
            <span class="btn-t" @click="withdrawalClick('coin')">{{$t('home.withdrawal')}}</span>
          </div>
        </div>
        <div class="t b1">
          <div class="h5">
            <span @click="infoToast($t('home.agentAmount'))">
              {{$t('withdrawal.agentAmount')}}
              <nut-icon name="ask" class="copyicon" style="vertical-align: bottom;"></nut-icon>
            </span>
            <strong>{{ $filters.currencySymbol(fundAccount.cashAccount) }}</strong>
          </div>
          <div class="btn-box">
            <span v-show="!commonInfo.pureMode" class="btn-t" @click="transferClick">{{$t('withdrawal.transfer')}}</span>
            <span class="btn-t" @click="withdrawalClick('cash')">{{$t('home.withdrawal')}}</span>
          </div>

        </div>
      </div>


    </div>


    <div class="my-info-b">

      <div class="t mt5"  v-show="userInfo.vipLevel > 0" @click="vipPopupClick">
        <h5>VIP Level {{ userInfo.vipLevel }}</h5>
      </div>


      <div class="t mr7"  @click="taskRewardClick">
        <h5>{{$t('userLogin.mobileNumber')}}</h5>
        <span>{{ userInfo.mobile}}</span>
      </div>
      
      <div class="t mr5"  @click="taskRewardClick">
        <h5>{{$t('global.reward')}}</h5>
        <span>{{$t('taskReward.checkIn')}} ></span>
      </div>

      <div class="t mr6" @click="copyClick(userInfo.inviteCode)">
        <h5>{{ $t('home.inviteCode') }}</h5>
        <span>{{ userInfo.inviteCode }}<nut-icon name="link" class="copyicon" ></nut-icon></span>
      </div>
    </div>

    <div class="m8" style="padding-bottom: 80px;">
      <nut-cell-group>
        <!-- <nut-cell
          :title="$t('home.link')"
          is-link
          @click="telegramClick"
          class="icon-t1"
        ></nut-cell> -->
        <nut-cell
          :title="$t('home.promotion')"
          is-link
          @click="promotionClick"
          class="icon-t2"
        ></nut-cell>
        <nut-cell
          :title="$t('home.envelopesCenter')"
          is-link
          :desc="unreadLetterCount"
          @click="envelopesCenterClick"
          class="icon-t3"
        ></nut-cell>
        <nut-cell
          :title="$t('home.transactions')"
          is-link
          @click="transactionsClick"
          class="icon-t4"
        ></nut-cell>
        <nut-cell
          :title="$t('home.bankCard')"
          is-link
          @click="bankCardClick"
          class="icon-t5"
        ></nut-cell>

        <!-- <nut-cell
          :title="$t('RechargeUSDT.h1')"
          is-link
          v-if="country === 'in'"
          @click="rechargeUSDTClick"
          class="icon-t10"
        ></nut-cell> -->

        <nut-cell
          :title="$t('home.resetPassword')"
          is-link
          @click="ResetPasswordClick"
          class="icon-t6"
        ></nut-cell>

        <!-- <nut-cell
          :title="$t('home.cs')"
          is-link
          @click="complaintsSuggestionsClick"
          class="icon-t7"
        ></nut-cell> -->

        <nut-cell
          v-show="downloadAppShow"
          :title="$t('global.downloadApp')"
          is-link
          @click="downloadApp"
          class="icon-t9"
        ></nut-cell>

      </nut-cell-group>

    </div>

    

    <nut-button
        :plain="booleanTrue"
        :block="booleanTrue"
        type="info"
        @click="logoutClick"
        style="margin:280px auto 20px auto; width:90%;"
        >{{$t('home.logout')}}
    </nut-button>


    <nut-popup
      position="bottom"
      closeable
      round
      :style="{ height: '70%' }"
      v-model:visible="showRound"
    >
      <div class="vip-popup">
        <h3>{{$t('home.vipTitle')}}</h3>

        <nut-tabs v-model="tab11value" type="smile" >
          <nut-tabpane :title="$t('home.withdrawakQuoat')">
            <table>
              <tr>
                <th>{{$t('home.level')}}</th>
                <th>{{$t('home.totalRecharge')}}</th>
                <th>{{$t('home.profitRatio')}}</th>
              </tr>
              <tr>
                <td>1</td>
                <td>&ge; 200</td>
                <td>1.25</td>
              </tr>
              <tr>
                <td>2</td>
                <td>&ge; 400</td>
                <td>1.40</td>
              </tr>
              <tr>
                <td>3</td>
                <td>&ge;1000</td>
                <td>1.45</td>
              </tr>
              <tr>
                <td>4</td>
                <td>&ge;2000</td>
                <td>1.50</td>
              </tr>
              <tr>
                <td>5</td>
                <td>&ge;10000</td>
                <td>1.55</td>
              </tr>
            </table>
          </nut-tabpane>
          <nut-tabpane :title="$t('home.withdrawalServices')"> </nut-tabpane>
          <nut-tabpane :title="$t('home.vipRewards')"> </nut-tabpane>
        </nut-tabs>

        <dl>
          <dt>{{$t('home.dt')}}</dt>
          <dd>{{$t('home.dd1')}}</dd>
          <dd>{{$t('home.dd2')}}</dd>
        </dl>
      </div>
    </nut-popup>

    <div class="telegram-btn" @click="telegramClick">
      <img src="../assets/images/bg208.png" style="height: 60px;">
    </div>

    <tabbar :activeIndex="tabbarActiveIndex"></tabbar>

    <div class="download-app" v-show="downloadAppShow">
      <a :href="appDownloadUrl">&nbsp;</a>
      <span @click="downloadAppClose"><nut-icon name="close" size="16" color="#ffffff"></nut-icon></span>
    </div>

    <nut-overlay v-model:visible="addDesktopShow" :z-index="2000" style="" class="add-Desktop" @click="downloadAppClose">
      <div class="wrapper">
        <div class="content">
          <p>{{$t('global.addDesktopTitle')}}</p>
          <p>{{$t('global.addDesktopText1a')}} <img src="../assets/images/bg64.png" /> {{$t('global.addDesktopText1b')}}</p>
          <p>{{$t('global.addDesktopText2')}}</p>
        </div>
      </div>
    </nut-overlay>




    <nut-popup pop-class="pop-vip" v-model:visible="vipPopup" :z-index="100">
      <VipRules :myLevel="myLv" :popTitle="popTitle"></VipRules>
    </nut-popup>

    <div class="to-help" @click="helpSupport"  v-if="commonInfo.supportCenterOn"></div>

  </div>
</template>

<script>
import { ref, reactive, onMounted, toRefs,getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import TabbarMain from "../components/TabbarMain.vue";
// import SetLanguage from "../components/SetLanguage.vue";
import {Dialog, Toast} from "@nutui/nutui";
import axios from "axios";
import { getClientType } from "@/assets/js/commonUtil";
import { getCountry } from "@/assets/js/environment";
import VipRules from "../components/VipRules2.vue";
export default {
  name: "MyHome",
  components: {
    tabbar: TabbarMain,
    // SetLanguage: SetLanguage,
    VipRules: VipRules
  },
  data() {
    return {
      country:'',
      clientType:'',
      downloadTitle:'',
      downloadAppShow: '',
      menuDownloadAppShow: '',
      appDownloadUrl:'',
      addDesktopShow: '',
      vipPopup:false,
      popTitle:'',
      i18n:{
        loading: this.$t('global.loading'),
        copied: this.$t('global.Your_invitation_link_has_been_copied'),
        email: this.$t('global.Through_Email1')+'@'+this.$t('global.Through_Email2'),
        ok: this.$t('global.okText')
      }
    };
  },
  methods: {
    
    infoToast(msg){
      Dialog({
        content: msg,
        okText: this.$t('global.okText'),
        noCancelBtn: true
      });
    },
    vipPopupClick(){
      this.vipPopup = true
      this.popTitle = this.$t('recharge.popTitle_1')
    },
    //下载app
    downloadApp(){
        window.open(this.appDownloadUrl);
    },
    downloadAppClose(){
      this.addDesktopShow = false;
      this.downloadAppShow = false;
      localStorage.setItem("downloadApp", 'close');
    },
    showDownloadTip() {
      this.clientType = getClientType();
      if (this.clientType == "ios") {
        this.addDesktopShow = true;
        this.downloadAppShow = false;
        this.downloadTitle = this.$t('global.addDesktop');
      } else if (this.clientType == "android") {
        this.addDesktopShow = false;
        this.downloadAppShow = true;
        this.menuDownloadAppShow = true;
        if (window.localStorage.getItem("commonInfo")) {
          let commonInfo = JSON.parse(window.localStorage.getItem("commonInfo"));
          this.appDownloadUrl = commonInfo.appDownloadUrl;
        } else {
          axios.get("/api/system/common", {}).then((response) => {
            this.appDownloadUrl = response.data.data.appDownloadUrl;
          });
        }
        if(this.appDownloadUrl === '-') {
          this.downloadAppShow = false;
          return
        }
        this.downloadTitle = this.$t('global.downloadApp');
      }
    }
  },
  created() {
    if(localStorage.getItem("downloadApp") === 'close'){
      this.downloadAppShow = false
      this.addDesktopShow = false
    }else{
      this.showDownloadTip();
    }
    this.country = getCountry();
  }, 
  setup() {
    const _data = getCurrentInstance();
    const switchChecked = ref(true);
    const state = reactive({
      commonInfo: JSON.parse(window.localStorage.getItem("commonInfo")),
      imgUrl: "",
      icon: "notice",
      booleanTrue: true,
      showRound: false,
      tabbarActiveIndex: 4,
      tab11value: "0",
      userInfo: {
        mobile: "",
        inviteCode: "",
        avatar: "",
        vipLevel: "",
        vipTitle: "",
        vipBg: "",
        myAvatar: "",
      },
      fundAccount: {
        cashAccount: "",
        coinAccount: "",
      },
      unreadLetterCount: 0,
    });
    const router = useRouter();
    const methods = {
      telegramClick(){
        window.open(state.commonInfo.telegramChannelUrl,"_self");
      },
      helpSupport(){
        router.push({ path: '/helpSupport' })
      },
      //按钮-VIP
      promotionClick() {
        router.push({
          name: "agentGrowth",
        });
      },
      //按钮-充值
      rechargeClick() {
        router.push({
          name: "rechargeBalance",
        });
      },
      //按钮-提现
      withdrawalClick(t) {
        router.push({
          path: 'withdrawalBalance',
					query: {
						amountType: t
					}
				})
      },
      //转换
      transferClick() {
        router.push({
          name: "cashTransfer",
        });
      },
      //任务页
      taskRewardClick() {
        router.push({
          name: "taskReward",
        });
      },
      //按钮-领取红包
      envelopesCenterClick() {
        router.push({
          name: "envelopesCenter",
        });
      },
      //按钮-钱包
      transactionsClick() {
        router.push({
          name: "transactionsHistoryList",
        });
        router.push({
          path: "transactionsHistoryList",
          query: {
            listType: 'coin'
          }
        })
      },
      //按钮-银行卡
      bankCardClick() {
        router.push({
          name: "bankCard",
        });
      },
      //按钮-修改密码
      ResetPasswordClick() {
        router.push({
          name: "resetPassword",
        });
      },
      //按钮-投诉建议
      complaintsSuggestionsClick() {
        // router.push({
        //   name: "complaintsSuggestions",
        // });
        Dialog({
          content: _data.data.i18n.email,
          okText: _data.data.i18n.ok,
          noCancelBtn: true
        });
      },
      //USDT充值
      rechargeUSDTClick() {
        router.push({
          name: "rechargeUSDT",
        });
      },

      //按钮-退出登录
      logoutClick() {
        localStorage.removeItem('token')
        localStorage.removeItem('hasPassword')
        localStorage.removeItem('firstVisit')
        router.push({
          name: "userLogin",
        });
      },
      
      iconClick() {
        router.push({
          name: "envelopesCenter",
        });
      },

      textToast(msg) {
        Toast.loading(msg, {
          id: "loading",
          duration: 0,
          bgColor: "rgba(0, 0, 0, 0.9)",
          cover: true,
        });
      },
      avatarClick(url){
        router.push({
          name: "setAvatar",
          query: {img:url}
        });
      },
      copyClick(inviteCode){
        let url = state.commonInfo.frontBaseUrl
        let cInput = document.createElement("input")
        cInput.value = url+'?invite='+inviteCode
        document.body.appendChild(cInput)
        cInput.select()
        document.execCommand("copy")
        Toast.success(_data.data.i18n.copied)
        document.body.removeChild(cInput)
      },
    };
    onMounted(() => {
      methods.textToast(_data.data.i18n.loading);
      state.imgUrl = localStorage.getItem('countryImgUrl')
      //请求数据
      axios
        .get("/api/user/home", {})
        .then(function (response) {
          state.userInfo.mobile = response.data.data.user.mobile;
          state.userInfo.inviteCode = response.data.data.user.inviteCode;
          state.userInfo.myAvatar = response.data.data.user.avatar;
          state.userInfo.avatar = 
            require('../assets/images/' + state.imgUrl + 'avatar/' + response.data.data.user.avatar.slice(response.data.data.user.avatar.length-6))
          state.userInfo.vipLevel = response.data.data.user.vipLevel;
          state.fundAccount.cashAccount = response.data.data.cashAccount.availableAmount;
          state.fundAccount.coinAccount = response.data.data.coinAccount.availableAmount;
          state.unreadLetterCount = response.data.data.unreadLetterCount;
          localStorage.setItem("inviteCode", response.data.data.user.inviteCode);
          if (response.data.data.unreadLetterCount === 0) {
            state.icon = "notice";
          }
          Toast.hide("loading");
          const vipRules = state.commonInfo.vipRules;
          for(var i = 0; i < vipRules.length; i ++){
            if(vipRules[i].level === state.userInfo.vipLevel){
              state.userInfo.vipBg = require('../assets/images/abg-'+ vipRules[i].level +'.png')
              state.userInfo.vipTitle = vipRules[i].title
            }
          }
        })
        .catch(function (error) {
          console.log(error);
          Toast.hide("loading");
        })
    });
    return {
      ...toRefs(state),
      ...methods,
      switchChecked,
    };
  },
};
</script>

<style scoped>
.download-app{ position: fixed; top:0; left: 0; right: 0; height: 44px; z-index: 501; 
  background:#604aae url(../assets/images/bg15.png) 0 0 repeat-y;background-size: 100% 100%;}

.download-app span{ position: absolute; right:10px; top:13px; opacity:.8;}

.add-Desktop .wrapper{position: absolute;bottom: 0; left: 0; right: 0; height: 190px; text-align: center; color: #fff;
  background: url(../assets/images/bg63.png) center bottom no-repeat; background-size:40px auto; }
.add-Desktop .wrapper p{ line-height: 20px; margin: 0; padding: 5px;}
.add-Desktop .wrapper img{ vertical-align: middle; width: 14px;}

.new-icon{position: absolute; right:7px;top:12px; width: 8px; height: 8px; border-radius: 4px; background:#f30b0b;z-index: 501; }
.nut-navbar, .nut-navbar-b0, .nut-navbar-b0 .nut-navbar {
  box-shadow: 0 0 0 !important;
}

.dot-right {
  position: absolute;
  top: 0;
  right: 20px;
}


.my-info-b .fc-FFC700{font-size: 18px;vertical-align: top;}
.my-info-b .nut-button--small{font-size: 14px !important;}
.my-info-b .nut-button--primary {background: linear-gradient(135deg,#ff9e0d 0%,#ffa70d 45%,#ffb60d 83%,#ffbe0d 100%) !important; min-width:70px !important;padding: 0px 10px !important;}
.my-info-b .nut-button--info {background: linear-gradient(315deg,#498ff2 0%,#4965f2 100%) !important;min-width:70px !important;padding: 0px 10px !important;}
.my-info-b .mr10{ padding:5px 0 5px 30px;background: url(../assets/images/fjjb.png) 5px 3px no-repeat;background-size: 20px auto;}


.nut-tabs__titles.normal .nut-tabs__titles-item {
  font-size: 12px;
}
.vip-popup h3 {
  font-size: 15px;
  padding: 10px;
}
.vip-popup table {
  width: 100%;
}
.vip-popup table th {
  border: 1px #ccc solid;
  background: #f4f1ff;
  line-height: 28px;
  font-weight: 400;
}
.vip-popup table td {
  border: 1px #ccc solid;
  line-height: 18px;
  text-align: center;
}
.vip-popup dl {
  padding: 10px;
}
.vip-popup dt {
  font-size: 14px;
  line-height: 24px;
  color: #555;
}
.vip-popup dd {
  font-size: 12px;
  color: #999;
}


.my-info {background: url(../assets/images/bg15_1225.png) 0 -80px no-repeat; background-size: 100% auto;
  margin-top: 43px; margin-bottom: 60px; height: 170px;}
 .in .my-info {background: url(../assets/images/bg15_1225b.png) 0 -80px no-repeat;background-size: 100% auto ;}
.my-info .my-info-t{ padding-top: 8px; text-align: center;}
.my-info .p-avatar { text-align: center;  padding-top: 10px; position: relative;}
.my-info .p-avatar img { width: 70px;height: 70px;}
.my-info .p-avatar .abg{ width: 78px; height: 78px; position: absolute; top:4px; left: calc(50% - 39px);}
.my-info .avatarimg{border-radius: 50%;}
.my-info .tx { color: #e27a00; font-size: 12px; border-radius: 5px 20px 20px 5px;
   max-width: 46px;line-height: 20px; height: 20px; text-align: center;padding-left: 16px;
   background: url(../assets/images/user1.png) 0 0 no-repeat; background-size:auto 20px;}
.my-info ul { padding: 8px 0; margin-bottom: 5px; width: calc(100% - 80px);}
.my-info ul li { line-height: 22px; height: 22px; color: #fff;}
.my-info ul li label { color: #ab94ff; margin-right: 2px;  width: 85px; float: left;}

.my-info-c{ display: flex; text-align: left;  margin-bottom: 15px; border-radius: 5px; margin: -22px 8px 0 8px; height: 85px; padding-top: 10px;
  background: #e5c854 url(../assets/images/in/EN/S2/bg199.png) right bottom no-repeat; background-size: 100% auto;}
.my-info-c .h5{ margin: 0; padding: 0; color: #fff; line-height: 24px; height: 45px; margin-bottom: 6px; font-size: 20px; position: relative; z-index: 105;}
.my-info-c span{ color: #fff; opacity: 1;line-height: 24px; font-size: 10px; margin-left: 0px; display: block;}
.my-info-c .t{ width: 50%; position: relative; z-index: 100; text-align: center;}
.my-info-c .b1{ border-left:1px solid #530151;}
.my-info-c .btn-box{ height: 20px; overflow: hidden; display: flex; justify-content: center;}
.my-info-c .btn-t{ margin-right: 5px; border: 0; background: #ffe477; color: #9261b5;  border-radius: 10px;font-size: 10px; 
  padding: 0px 8px; line-height: 20px; opacity: .8;  height: 20px;overflow: hidden; display: inline-block; }

.my-info-b { color: #fff;padding: 5px 0; position: absolute; background: #1b281b; overflow: hidden;
   line-height: 22px; height: 230px; width: calc(46% - 10px); margin: 10px 0 0 8px; border-radius: 5px;}
.my-info-b h5{ margin: 0; padding: 0; color: #fff; line-height: 22px; padding-top: 3px; margin-left: 10px;}
.my-info-b span{ color: #fff; opacity: .5;line-height: 18px;margin-left: 10px; }
.my-info-b span i{ vertical-align: sub;}
.my-info-b .t{} 
.my-info-b .mr5{ background: url(../assets/images/in/EN/S2/bg98.png) 5px center no-repeat; background-size: 40px auto;padding-left: 40px;}
.my-info-b .mr6{ background: url(../assets/images/in/EN/S2/bg97.png) 7px center no-repeat; background-size: 36px auto;padding-left: 40px;}
.my-info-b .mr7{ background: url(../assets/images/in/EN/S2/bg97b.png) 7px center no-repeat; background-size: 36px auto;padding-left: 40px;}

.m8 .nut-cell{padding: 10px 16px; color: #342362;}

</style>
<style>
.m8 .nut-cell-group .nut-cell:after {border-bottom: 1px solid #fff !important;}
.m8 .nut-cell-group .nut-cell__title {margin-left: 25px !important;}
.m8 .nut-cell__link{ color: #d2c8ed;}
.nut-actionsheet-panel .nut-actionsheet-menu{padding-bottom: 75px !important}
.nut-popup .nut-dialog{padding: 28px 14px 16px !important;}

.icon-t1 .nut-cell__value{background: #ffd450 url(../assets/images/bg194.png) 5px center no-repeat; background-size: 18px; 
  color: #6c1207; padding: 0px 10px 0 30px; border-radius:10px;}
 .login-bd .icon-t1 .nut-cell__value{background: #fff}
</style>
