
<template enter-active-class='animate__animated animate__fadeInDown'  leave-active-class='animate__animated animate__fadeOutUp' :duration="1000">
  <div class="login userLogin">
    <div class="login-bn">
      <h2>&nbsp;</h2>
    </div>
    <div class="login-bd">
      <h3>{{$t('resetPassword.h1')}}</h3>
      <div class="login-inp">
        <p class="fs10 fc-999">{{ $t('userLogin.mobileNumber')}}</p>
        <p class="err-info" v-show="errText.mobile">{{errText.mobile}}</p>
        <p class="err-info" v-show="state.mobileErrText">{{state.mobileErrText}}</p>
        <div v-if="country === 'in'" class="nation">+91</div>
        <div v-if="country === 'ph'" class="nation">+63</div>
        <div v-if="country === 'br'" class="nation">+55</div>
        <div v-if="country === 'ng'" class="nation">+234</div>
        <div v-if="country === 'mx'" class="nation">+52</div>
        <div v-if="country === 'vn'" class="nation">+84</div>
        <login-input
          label=""
          :placeholder="$t('userLogin.mobileNumberPlaceholder')"
          type="number"
          rule="^.{6,18}$"
          class="pl40"
          @inputChange="(res) => (requesInfo.mobile = res)"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{ $t('userRegister.verificationCode')}}</p>
        <p class="err-info" v-show="errText.authCode">{{errText.authCode}}</p>
        <div class="nut-input-inner w100">
          <login-input
            label=""
            :placeholder="$t('userRegister.verificationCodePlaceholder')"
            type="number"
            rule="^\d{6}$"
            maxLength="6"
            @inputChange="(res) => (requesInfo.authCode = res)"
          />
          <nut-button
            type="info"
            size="mini"
            v-show="state.sendAuthCode"
            @click="getCode(requesInfo.mobile)"
            >{{ $t('userRegister.OTP') }}</nut-button
          >
          <nut-button
            type="info"
            size="mini"
            v-show="!state.sendAuthCode"
            :disabled="true"
            >{{ state.second }} s</nut-button
          >
        </div>
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{ $t('resetPassword.newPassword') }}</p>
        <p class="err-info" v-show="errText.password">{{errText.password}}</p>
        <login-input
          label=""
          :placeholder="$t('resetPassword.newPasswordPlaceholder')"
          type="password"
          rule="^.{6,32}$"
          maxLength="32"
          @inputChange="(res) => (requesInfo.password = res)"
          @blur="verifyPassword"
        />
      </div>

      <div class="login-inp">
        <p class="fs10 fc-999">{{ $t('resetPassword.confirmPassword') }}</p>
        <p class="err-info" v-show="errText.confirmPassword">{{errText.confirmPassword}}</p>
        <login-input
          label=""
          :placeholder="$t('resetPassword.confirmPasswordPlaceholder')"
          type="password"
          rule="^.{6,32}$"
          maxLength="32"
          @inputChange="(res) => (requesInfo.confirmPassword = res)"
          @blur="verifyPassword"
        />
      </div>


      <div class="login-btn">
        <nut-button block type="info" @click="confirmClick">{{$t('global.confirm')}}</nut-button>
      </div>
      <nut-row>
        <nut-col :span="24">
          <div class="flex-content" style=" text-align: center;">
            <span class="a-lk" @click="registerClick">{{ $t('userLogin.register')}}</span>
          </div>
        </nut-col>
      </nut-row>
    </div>

    <div class="login-tx">
      {{ $t('global.safeDays1')}} <strong>{{ state.runningDays }}</strong> {{ $t('global.safeDays2')}}
    </div>
  </div>
</template>

<script>
import { reactive,onMounted,getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { Toast } from "@nutui/nutui";
import axios from "axios";
import loginInput from "@/components/loginInput.vue";
import { getCountry } from "@/assets/js/environment";

export default {
  data() {
    return {
      country: '',
      requesInfo:{
        mobile: "",
        authCode: "",
        password: "",
        confirmPassword: "",
      },
      errText:{
        mobile: "",
        authCode: "",
        password:"",
        confirmPassword:"",
      },
      shareCode: "",
      shareBonusReceiveCode:"",
      confirmPassword : false,
      i18n:{
        successfully: this.$t('global.successfully'),
        errorfully: this.$t('global.errorfully'),
        mobileError: this.$t('global.INVALID_INPUT_VALUE'),
        networkError: this.$t('global.networkError')
      }
    };
  },
  components: {
    loginInput,
  },
  created() {
    this.country = getCountry()
  },
  methods: {
    verifyPassword(){
      if(this.requesInfo.password != this.requesInfo.confirmPassword && this.requesInfo.password && this.requesInfo.confirmPassword){
        this.errText.confirmPassword = this.$t('global.passwordsError')
        this.confirmPassword = false
      }else{
        this.errText.confirmPassword = null
        this.confirmPassword = true
      }
    },
    confirmClick() {
        this.errText.mobile = ""
        this.errText.authCode = ""
        this.errText.password = ""
        this.errText.confirmPassword = null
        if(this.requesInfo.mobile === ""){
            this.errText.mobile = this.$t('global.errInfoRequired')
            return
        }
        if(this.requesInfo.authCode === ""){
            this.errText.authCode = this.$t('global.errInfoRequired')
            return
        }
        if(this.requesInfo.password === ""){
            this.errText.password = this.$t('global.errInfoRequired')
            return
        }
        if(this.requesInfo.confirmPassword === ""){
            this.errText.confirmPassword = this.$t('global.errInfoRequired')
            return
        }
        if(!this.confirmPassword){
            this.errText.confirmPassword = this.$t('global.passwordsError')
            return
        }
        axios
        .post("/api/user/forget-password", {
        newPassword: this.requesInfo.password,
        mobile: this.requesInfo.mobile,
        authCode: this.requesInfo.authCode,
        })
        .then((response) => {
        if (response.data.code === 0) {
            //Toast.success(this.$t('resetPassword.success'));
            Toast.success(this.$t('resetPassword.success'), {
              duration: 4000
            });

            setTimeout(() => {
                this.$router.push("/myHome");
            }, 1000);
        }else if (response.data.code === 1004){
              let res = response.data.data
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "mobile":
                    this.errText.mobile = res[i].description
                    break;
                  case "authCode":
                    this.errText.authCode = res[i].description
                    break;
                  case "newPassword":
                    this.errText.password = res[i].description
                    break;
                }
              }
            }else{
                if(response.data.msg != undefined){
                    Toast.fail(response.data.msg);
                }
            }
        })
        .catch(function (error) {
            console.log(error);
            Toast.fail(this.$t('global.networkError'));
        });
    },
  },

  setup() {
    const _data = getCurrentInstance();
    const state = reactive({
      runningDays: '',
      sendAuthCode: true /* 通过v-show控制显示‘获取按钮’还是‘倒计时’ */,
      second: 60 /* 一分钟 倒计时*/,
      timer: null /* 倒计时 计数器,防止点击的时候触发多个setInterval*/,
      mobileErrText: ''
    });
    const getCode = (m) => {
      let mobile = m;
      state.mobileErrText = ''
      if (mobile != "") {
        axios
          .post("/api/system/sms/send", {
            mobile: mobile,
            type: "forget_password_code",
          })
          .then(function (reg) {
            if(reg.data.code === 0){
              //Toast.success(_data.data.i18n.successfully);
              Toast.success(_data.data.i18n.successfully, {
                duration: 4000
              });
              if (state.sendAuthCode) {
                  state.sendAuthCode = false;
                  let interval = window.setInterval(function () {
                  setStorage(state.second);
                  if (state.second-- <= 0) {
                      //如果 倒计时完毕 重新赋值
                      state.second = 60;
                      state.sendAuthCode = true;
                      window.clearInterval(interval);
                  }
                  }, 1000);
              }
            } else if (reg.data.code === 1004){
              let res = reg.data.data
              Toast.fail(_data.data.i18n.mobileError);
              for(var i in res){	
                switch (res[i].fieldName) {
                  case "mobile":
                    state.mobileErrText = res[i].description
                    break;
                }
              }
            }
          })
          .catch(function (error) {
            console.log(error);
            Toast.fail(_data.data.i18n.networkError); 
          });
      } else {
        state.mobileErrText = _data.data.i18n.mobileError
      }
    };
    /**
     * 存储 验证码 防止刷新
     * `用处`: 防止页面刷新 发送验证码状态改变
     * */
    const setStorage = (parm) => {
      localStorage.setItem("dalay", parm);
      localStorage.setItem("_time", new Date().getTime());
    };
    /**
     * 获取 缓存 数据
     * `用处`: 防止页面刷新 发送验证码状态改变
     * */
    const getStorage = () => {
      let localDelay = {};
      localDelay.delay = localStorage.getItem("dalay");
      localDelay.sec = localStorage.getItem("_time");
      return localDelay;
    };
    /**
     *  判断
     *  */
    const judgeCode = () => {
      // 获取缓存中的数据
      let localDelay = getStorage();
      let secTime = parseInt((new Date().getTime() - localDelay.sec) / 1000);
      if (secTime > localDelay.delay) {
        state.sendAuthCode = true;
      } else {
        state.sendAuthCode = false;
        let _delay = localDelay.delay - secTime;
        state.second = _delay;
        state.timer = setInterval(() => {
          if (_delay > 1) {
            _delay--;
            setStorage(_delay);
            state.second = _delay;
            state.sendAuthCode = false;
          } else {
            // 让浏览器打开的时候,显示剩余的时间
            state.sendAuthCode = true;
            window.clearInterval(state.timer);
          }
        }, 1000);
      }
    };
    judgeCode();
    const router = useRouter();
    const loginClick = () => {
      router.push({
        name: "userSMSLogin",
      });
    };
    const registerClick = () => {
      router.push({
        name: "userRegister",
      });
    };
    onMounted(() => {
      if(window.localStorage.getItem('commonInfo')){
        let commonInfo = JSON.parse(window.localStorage.getItem('commonInfo'))
        state.runningDays = commonInfo.runningDays
      }else{
        axios.get("/api/system/common", {}).then((response)=> {
          state.runningDays = response.data.data.runningDays
        })
      }
    })
    return {
      state,
      getCode,
      loginClick,
      registerClick,
    };
  },
};
</script>

<style scoped>
.login-inp{ position: relative;}
.login-inp .pl40{ margin-left: 66px; width: auto !important;}

.login-bd {
  padding: 20px;
  background: #fff;
  margin: 20px;
  box-shadow: 0px 0px 5px #d9d2f4;
  border-radius: 10px;
}
.login-bd h3 {
  margin: 10px 0 30px 0;
  font-size: 20px;
  color: #333;
  font-weight: 400;
  text-align: center;
}
.login-bd .nut-input {
  padding: 10px 0 10px 0px;
}
.login-bd .nut-input .input-text {
  padding: 0 35px 0 0px;
}
.a-lk {
  font-size: 12px;
  color: #0c3901;
  display: block;
  line-height: 14px;
}

.login-btn {
  margin: 30px 0 10px 0;
}
.login-tx {
  padding: 20px 0;
  color: #809c54;
  text-align: center;
  background: #f1f7e8;
}
.login-tx strong {
  font-size: 20px;
  border: 1px solid #bac6a7;
  box-shadow: 0px 0px 3px #d9d2f4;
  margin-right: 2px;
  padding: 2px 4px;
  border-radius: 4px;
  background: url(../assets/images/bg06.png) center center no-repeat;
}
.login-bn {
  padding: 88px 20px 20px 20px;
}
.login-bn h2 {
  padding: 0;
  margin: 0;
  font-size: 40px;
  text-align: center;
  color: #efe7f4;
}
</style>
