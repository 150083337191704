<template>
  <div class="play-rule">
    <h4>Game Rules</h4>
    <dl>
      <dt>How to play</dt>
      <dd>Choose your bet amount. Click "Start" and the multiplier will start rising from 1.00x,You can click "Stop" at any time to end the game and receive corresponding rewards.
         Please note: the rocket may explode at any time, and if you don't escape before then, you will get nothing!</dd>


      <dt>How rewards are calculated</dt>
      <dd>If your order amount is 100, the escape multiplier is 5.0x, and after deducting a handling fee of 3, the final bet amount is 97,The reward is (97 * 5) 485</dd>
      
      <dt>How high can the rocket up to?</dt>
      <dd>X100 </dd>
      

      <dt>What is automatic stop</dt>
      <dd>If you choose to stop automatically, when the multiplier reaches the multiplier you set, it will automatically stop and get the reward</dd>




      


















    </dl>

    

  </div> 
</template>

<script>

export default {
  name: 'GameRules',
  props: {},
  data() {
    return {
      imgUrl:''
    };
  },
  methods: {
  },
  created() {
    this.imgUrl = localStorage.getItem('countryImgUrl')
  },
  setup() {}
};
</script>

<style scoped>
.popclass-pp {
  padding: 15px 5px 10px 15px !important;
  border-radius: 5px;
  width: 70%;
}
.popclass-pp h4 {
  line-height: 30px;
  padding-bottom: 10px;
  font-size: 16px;
  margin: 0 !important;
  border-bottom: 1px solid #eee;
}
.popclass-pp .txbd {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
.play-rule {
  padding: 20px;
}

.play-rule h4 {
  margin: 5px 0;
  font-size: 17px;
}
.nut-popup h4.h4 {
  margin: 20px 10px;
}
.play-rule dl {
  position: absolute;
  overflow-y: scroll;
  top: 50px;
  bottom: 20px;
}

.play-rule dt {
  margin: 20px 0; padding-left: 10px; 
  font-size: 17px; font-weight: 500;  padding-left: 30px;
  background: url(../assets/images/in/EN/S2/icon-02.png) 0 center no-repeat; background-size: 20px auto;
}

.play-rule dd {
  margin:0 10px 5px 10px;
}
.play-rule dd.img {
  margin:0 10px 5px 0px;
}
.play-rule dd img{
  width: 100%;
}
.play-rule dd strong{ color: #0c3901;}
.play-rule .nut-button{ margin-right: 20px; width: auto !important;}
</style>
