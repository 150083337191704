<template>
  <div>
    <nut-navbar :left-show="false" :title="$t('HELP.H1')" fixed=true @on-click-icon="iconClick" titIcon="ask2"
      class="mb0 nav-icon-right nut-navbar--fixed">
      <template #left>
        <i class="nutui-iconfont nut-icon nut-icon-left" @click="backClick"></i>
      </template>
    </nut-navbar>

    <div class="pt50 scroll-body">
      <div class="p20" style="padding:10px 5px !important">

        <div class="type-title" style="display: none;">
          <span v-if="helpType.includes('recharge')">Recharge</span>
          <span v-else-if="helpType.includes('withdraw')">Withdraw</span>
          <span v-else-if="helpType.includes('business')">Promotion</span>
          <span v-else-if="helpType.includes('feedback')">Others</span>
          <span v-else>Help</span>
        </div>

        <div class="csls" id="scroll" >

          <div v-for="(item, index) in list" :key="index"
            :class="item.sender === 'user' ? 'csls-item t1' : 'csls-item t3'">
            <h4>
              <span class="r">{{ item.createTime }}</span>
            </h4>
            <div class="bd">
              {{ item.content }}
            </div>
            <div class="imgs" v-if="item.imgs.length > 0">
              <img v-for="(itemImg, index) in item.imgs" :key="index" :src="itemImg" @click="checkImg($event, itemImg)" />
            </div>
          </div>

        </div>

      </div>
    </div>

    <nut-button
        v-if="status !== '4'"
        :disabled="status === '4'"
        type="info"
        @click="showReply"
        style="position: fixed; left: 10px; right: 10px; bottom: 2px;"
        >{{$t('HELP.REPLY')}}</nut-button>

    <!--**************************************** 图片预览 ****************************************-->
    <nut-popup :style="{ width: '90%' }" v-model:visible="showPreview">
      <div class="preview-pop" @click="showPreview = false">
        <img :src="uploadImgBase64">
      </div>
    </nut-popup>


  <!--**************************************** form ****************************************-->
  <nut-popup pop-class="popclass pop-cpf" closeable round close-on-click-overlay='false' @close="closeForm"
      :style="{ padding: '20px',width:'352px', height:'auto' }" v-model:visible="showReplyPopup" :z-index="100">
      <div class="formbox reply-box">
        <h3>{{$t('HELP.REPLY')}}</h3>
        <nut-form>

          <nut-form-item :label="$t('HELP.REPLY_CONTENT')">
            <nut-input v-model="content" :placeholder="$t('HELP.PLEASE_INPUT')" type="text" />
          </nut-form-item>

          <nut-form-item label="Screenshot">

            <div class="file-main">
                <div class="file-box" @click="callFile('#file1')">
                  <img :src="uploadImgBase64_1" v-if="uploadImgBase64_1" >
                </div>
                <input type="file" id="file1" accept="image/*" @change="getPicture($event, 0)" style="display: none;" />

                <div class="file-box" @click="callFile('#file2')" v-if="uploadImgBase64_1">
                  <img :src="uploadImgBase64_2" v-if="uploadImgBase64_2" >
                </div>
                <input type="file" id="file2" accept="image/*" @change="getPicture($event, 1)" style="display: none;" />

                <div class="file-box" @click="callFile('#file3')" v-if="uploadImgBase64_2">
                  <img :src="uploadImgBase64_3" v-if="uploadImgBase64_3" >
                </div>
                <input type="file" id="file3" accept="image/*" @change="getPicture($event, 2)" style="display: none;" />

            </div>

          </nut-form-item>

        </nut-form>
        <nut-button block type="info" :disabled="btnDisabled" @click="submitClick" style="margin: 0 2% 10px 2%; width: 96% !important;">
            <div v-if="btnDisabled" class="btn-disabled">
              <nut-icon name="loading" size="28" style="margin-top:8px; color: #4f006a;"></nut-icon>
            </div>
            <span v-else>{{$t('HELP.REPLY')}}</span>
        </nut-button>
      </div>
      
  </nut-popup>

  </div>
</template>

<script>
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import axios from "axios";
import { Toast } from '@nutui/nutui';
import {isNotBlank} from "@/assets/js/commonUtil";

export default {
  data() {
    return {
      SystemTicket2StatusEnum: {
        userReply: "user_reply",
        adminReply: "admin_reply",
        closed: "closed"
      },
      SystemTicket2TypeEnum: {
        recharge: 'recharge',
        withdraw: 'withdraw',
        invite: 'invite',
        others: 'others',
      },
      id: '',
      helpType: '',
      status: '',
      createTime: '',
      list: null,
      systemNotification: false,
      showPreview: false,  //图片预览
      uploadImgBase64: '',
      showReplyPopup: false,

      btnDisabled: false,
      content:'',
      imgs: {
        img1: null,
        img2: null,
        img3: null,
      },
      uploadImgUrl_1: '',
      uploadImgUrl_2: '',
      uploadImgUrl_3: '',
      uploadImgBase64_1: '',
      uploadImgBase64_2: '',
      uploadImgBase64_3: '',
    };

  },
  methods: {
    gettingData() {
      if (this.$route.query.id) {
        this.id = this.$route.query.id
      }
    },

    textToast(msg) {
      Toast.loading(msg, {
        id: "loading",
        duration: 0,
        bgColor: "rgba(0, 0, 0, 0.9)",
        cover: true,
      });
    },
    checkImg(e, url) {
      this.uploadImgBase64 = url;
      this.showPreview = true;
      e.stopPropagation();
      e.preventDefault()
    },
    showReply(){
      this.showReplyPopup = true
    },
    closeForm(){
      this.content = ''
      this.imgs = {
        img1: null,
        img2: null,
        img3: null,
      }
    },
    //上传图片
    submitClick(){
      this.textToast(this.$t('global.loading'))
      this.btnDisabled = true
      let _formData = new FormData();
      let _uploadImgForm = {
        content: this.content,
        ticketId: this.id,
      }
      for( var key in _uploadImgForm){
        _formData.append(key, _uploadImgForm[key])
      }
      if(isNotBlank(this.imgs.img1)){
        _formData.append('imgs',this.imgs.img1)
      }
      if(isNotBlank(this.imgs.img2)){
        _formData.append('imgs',this.imgs.img2)
      }
      if(isNotBlank(this.imgs.img3)){
        _formData.append('imgs',this.imgs.img3)
      }
      
      axios({
        method: "post",
        url: "/api/system/ticket2/reply",
        headers:{
          "Content-Type": "multipart/form-data"
        },
        withCredentials:true,
        data: _formData
      }).then((res) => {
        if (res.data.code === 0) {
            Toast.success("success!");
            setTimeout(() => {
              this.getDetail(this.id);
            }, 200);
          } else if (res.data.code === 1004) {
            let _res = res.data.data
            for(var i in _res){	
              switch (_res[i].fieldName) {
                case "content":
                Toast.fail('Reply content ' + _res[i].description);
                break;
              }
            }
          }else{
            if (res.data.msg != undefined) {
              Toast.fail(res.data.msg);
            }
          }
        this.btnDisabled = false
        this.showReplyPopup = false
        Toast.hide("loading")
      })
      

    },

    getPicture(e, n){
      var reader = new FileReader();
      switch (n) {
          case 0:
            reader.onload = (e) => {
              this.uploadImgBase64_1 = e.target.result
            }
            this.imgs.img1 = e.target.files[0]
            reader.readAsDataURL(e.target.files[0])
            break;
          case 1:
            reader.onload = (e) => {
                this.uploadImgBase64_2 = e.target.result
            }
            this.imgs.img2 = e.target.files[0]
            reader.readAsDataURL(e.target.files[0])
            break;
          case 2:
            reader.onload = (e) => {
              this.uploadImgBase64_3 = e.target.result
            }
            this.imgs.img3 = e.target.files[0]
            reader.readAsDataURL(e.target.files[0])
            break;
          default:
            break;
        }
    },
    callFile(f){
        let fileDom = document.querySelector(f)
        fileDom.click()
    },


    getDetail(id) {
      this.textToast(this.$t('global.loading'))
      axios({
        url: "/api/system/ticket2/detail",
        method: "get",
        params: { ticketId: id },
      }).then((res) => {
        this.helpType = res.data.data.type
        this.status = res.data.data.status
        this.createTime = res.data.data.createTime
        this.list = res.data.data.contentList
        Toast.hide("loading")
      });
    },

  },
  created() {
    this.textToast(this.$t('global.loading'))
    this.gettingData();
    this.getDetail(this.id);
  },

  setup() {
    const state = reactive({
    });
    const router = useRouter();
    const methods = {
      backClick() {
        router.go(-1);
      },
      iconClick() {
        router.push({
          name: "helpSupport",
        });
      },
    };
    return {
      ...toRefs(state),
      ...methods
    };
  },
};
</script>

<style scoped>
.type-title {
  padding: 0;
  text-align: center;
  position: fixed;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 90;
  height: 34px;
  padding-top: 15px;
  background: #123d17;
}

.type-title span {
  margin: 0 1%;
  line-height: 26px;
  height: 26px;
  width: 50%;
  color: #00EDA6;
  display: inline-block;
  border: 0;
}
.in .nut-input{ background: #eee;}
.csls{ padding-bottom: 40px;}
.in .scroll-body{ background: #000;}
.csls-item {
  border: 1px solid #123d17;
  margin-top: 10px;
  border-radius: 20px 20px 0 30px;
  overflow: hidden;
  margin-left: 50px;
  margin-right: 0; color: #fff; background: #123d17;
}

.csls-item.t3 {
  border: 1px solid #77ee8d;
  margin-top: 10px;
  border-radius: 20px 20px 30px 0;
  overflow: hidden;
  margin-left: 0px;
  margin-right: 50px;
}

.csls-item.t3 h4 {
  background:#1e5b25 url(../assets/images/bg303.png) 5px 2px no-repeat;
  background-size: 30px;
  padding-left: 30px;
}

.csls-item h4 {
  margin: 0;
  height: 40px;
  line-height: 40px;
  padding: 0 10px;
  background: url(../assets/images/bg183.png) 5px 10px no-repeat;
  background-size: 20px;
  padding-left: 30px;
}

.csls-item h4 .r {
  float: right;
  font-size: 14px;
  font-weight: 300;
  opacity: .8;
}

.csls-item .bd {
  padding: 10px;
  line-height: 20px;
  margin-bottom: 10px;
}

.csls-item .imgs {
  border-top: 1px dashed #402b51;
  padding: 10px;
}

.csls-item .imgs img {
  width: 33%;
  height: auto;
  max-width: 200px;
  max-height: 150px;
}
.file-main{ display: flex;}
.file-box{ margin-right: 10px;}
.btn-disabled{     height: 40px;
    background: #ffffff;
    display: block;
    position: absolute;
    left: -2px;
    right: -2px;
    top: -2px;
    opacity: .5;}
</style>

<style>.nav-icon-right .nut-navbar__title .nut-icon {
  position: absolute !important;
  right: 10px !important;
}</style>